<template>
  <div class="tool">
    <div class="toolbar-header">
      <div class="row">
        <!-- rimettere "meta" nelle classi per rimettere il bordo -->
        <div class="col-2 col-sm-5 col-md-7 col-lg-8 col-xl-9 topbar"></div>
        <!-- <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1 meta" style="min-width: 110px">{{ labels.AreaPersonale ? labels.AreaPersonale : "" }}</div> -->
        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1 meta" style="min-width: 110px"></div>
        <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 meta">
          <select v-model="language" @change="getLabels(language.code)">
            <option v-for="lang in options" :key="lang.code" :value="lang">{{ lang.label }}</option>
          </select>
          <flag class="flag" :squared="false" :iso="language.flag" />
        </div>
      </div>
    </div>
    <div class="toolbar toolbar-wrapper">
      <div class="asd toolbar-item logo-wrapper">
        <div v-if="$hotel.logo"><img :src="$hotel.logo" :style="{ height: '5.5em', cursor: 'pointer' }" @click="reset" /></div>
      </div>
      <div class="asd toolbar-item hotel-wrapper" style="bottom: 0px">
        <h3 class="hotel-title">{{ $hotel.riga1 }}</h3>
        {{ $hotel.riga2 }}<br />
        {{ $hotel.riga3 }}<br />
        {{ $hotel.riga4 }}
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../services/api";
export default {
  name: "CybToolBar",
  data() {
    return {
      language: "",
      // vengono caricate dal DB
      options: [
        { label: "Italiano", flag: "it", code: "ITA" },
        { label: "Inglese", flag: "gb", code: "ENG" },
        { label: "Francese", flag: "fr", code: "FRA" },
        { label: "Tedesco", flag: "de", code: "TED" }
      ],
      labels: {}
    };
  },
  mounted() {
    this.$root.$on("lang:request", (lingua) => {
      this.getLabels(lingua);
    });
  },
  methods: {
    reset() {
      if (this.$store.form) {
        this.$store.form = null;
        this.$router.push("/?RPC_db=" + this.$store.db + "&idhotel=" + this.$store.idhotel + "&lan=" + this.$store.lang);
      }
    },
    getLabels(lang = "ita") {
      apiService.get("BE_booking/lingue/" + this.$store.db + "/" + this.$store.idhotel + "/" + lang).then((resp) => {
        this.$root.$emit("lang:change", resp.labels);
        this.$store.lang = resp.lingua;
        this.$store.idlang = resp.idlingua;
        this.$store.descsottototali = resp.descsottototali;

        // label toolbar
        this.labels = resp.labels;
        if (this.labels.lingue) {
          this.options = [];
          for (let i = 0; i < this.labels.lingue.length; i++) {
            this.options[i] = {
              label: this.labels.lingue[i].label,
              flag: this.codeFlag(this.labels.lingue[i].codice),
              code: this.labels.lingue[i].codice
            };
          }
        }

        // selettore (get)
        var preselected = this.options.find((item) => item.code === lang.toUpperCase() || item.flag === lang);
        if (preselected) {
          this.language = preselected;
        }
        if (this.$store.lang && this.$store.idlang) {
          this.$root.$emit("rooms:reload");
          this.$root.$emit("services:reload");
          this.$root.$emit("form:reload");
          this.$root.$emit("thanks:reload");
        }
      });
    },
    codeFlag(code) {
      switch (code.toUpperCase()) {
        case "ITA":
          return "it";
        case "ENG":
        case "ING":
          return "gb";
        case "FRA":
          return "fr";
        case "TED":
          return "de";
      }
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 400px) {
  .toolbar-header,
  .toolbar {
    left: 0;
    right: 0;
    padding: 0 2% !important;
  }
}
@media (max-width: 1200px) {
  .toolbar-header {
    .row {
      width: 92%;
    }
  }
}
.tool {
  z-index: 99;
  position: sticky;
  top: 0;
}
.toolbar-header,
.toolbar {
  left: 0;
  right: 0;
  padding: 0 6.2%;
}
.toolbar-header {
  font-size: 0.7em;
  background: white;
  opacity: 0.92;
  .row {
    width: 100%;
  }
  .meta {
    text-transform: uppercase;
    &.topbar {
      text-align: right;
    }
    &:not(.topbar),
    &:not(.topbar) select {
      cursor: pointer;
    }
    select {
      border: none;
      background-color: transparent;
      outline: none !important;
      &:active {
        outline: none;
      }
    }
    .flag {
      margin-left: 5px;
    }
    text-align: center;
    padding: 0;
    margin: 0;
    border-right: 1px solid #cacaca;
    overflow: hidden;
    white-space: nowrap;
  }
}
.toolbar {
  margin: 0;
  position: absolute;
  max-height: 6em;
  width: 100%;
  background-color: white;
  opacity: 0.8;
  text-align: left;
  &.toolbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .toolbar-item {
      display: flex;
      flex-direction: column;
      &.logo-wrapper {
        text-align: right;
        min-width: 130px;
        align-items: left;
      }
      &.hotel-wrapper {
        align-items: right;
        text-align: right;
        font-size: 0.8em;
        .hotel-title {
          font-size: 2.2em;
        }
      }
    }
  }
  h3 {
    margin: 0;
    padding: 0;
  }
}
</style>
