<template>
  <div class="footer">
    {{ labels.privacy.before + _label(labels.privacy.before) }}
    <br v-if="labels.privacy.before && labels.privacy.before.endsWith('\n')" />
    <a href="https://www.iubenda.com/privacy-policy/946552">{{ labels.privacy.pri }}</a>
    {{ _label(labels.privacy.middle) + labels.privacy.middle + _label(labels.privacy.middle) }}
    <a href="https://www.iubenda.com/privacy-policy/946552/cookie-policy">{{ labels.privacy.cookie }}</a>
    <br v-if="labels.privacy.cookie && labels.privacy.cookie.endsWith('\n')" />
    {{ _label(labels.privacy.after) + labels.privacy.after }}
  </div>
</template>

<script>
import baseMixin from "../mixins/base";
export default {
  mixins: [baseMixin],
  props: {
    labels: { type: Object, default: () => {} }
  }
};
</script>

<style lang="scss">
.footer {
  position: absolute;
  text-align: center;
  padding: 22px 0 0;
  font-size: 0.8em;
  background: #ffffffc4;
  min-height: 80px;
  left: 0;
  right: 0;
  a {
    font-weight: bold;
  }
}
</style>
