import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import VueAgile from "vue-agile";
import FlagIcon from "vue-flag-icon";
import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css";
import "../src/css/variables.scss";
import App from "./App.vue";
import config from "./config.js";
import "./css/baselist.scss";
import router from "./router";
import store from "./store.js";

library.add(far);
library.add(fas);

Vue.component("fa", FontAwesomeIcon);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(FlagIcon);
Vue.use(VueModalTor);
Vue.use(VueAgile);

Vue.config.productionTip = false;
Vue.use(store);
Vue.use(config);

// eslint-disable-next-line no-undef
if (typeof postData !== "undefined") {
  // eslint-disable-next-line no-undef
  Vue.prototype.$postData = postData;
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
