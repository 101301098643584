import apiService from "../services/api";
import formService from "../services/form";

const bookingMixin = {
  methods: {
    // gestione errori
    _error(err = "") {
      if (err) {
        if (typeof err === "string") {
          this.$store.err.push(err);
        } else {
          for (let i = 0; i < err.length; i++) {
            this.$store.err.push(err[i]);
          }
        }
      }
      this.$router.push("/errore");
    },
    tariffa_label(camera) {
      if (camera.codice && camera.titolo) {
        var subtitle = camera.tariffa.tipo;
        subtitle += " [" + camera.tariffa.trattamento;
        if (camera.tariffa.sconto) {
          subtitle += "] " + this._sconto(camera.tariffa);
        } else {
          subtitle += "] " + camera.tariffa.prezzo;
        }
        return subtitle;
      }
      return "";
    },
    camera_label(camera) {
      var idx = camera.key + 1;
      if (camera.codice && camera.titolo) {
        return (
          formService._upper(this.labels.Room) +
          " " +
          idx +
          ": " +
          camera.titolo
        );
      }
      return formService._upper(this.labels.Room) + " " + idx;
    },
    camera_label_active(ospiti) {
      for (let index = 0; index < ospiti.length; index++) {
        var camera = ospiti[index];
        if (!camera.active) {
          continue;
        }
        var idx = camera.key + 1;
        if (camera.codice && camera.titolo) {
          return (
            formService._upper(this.labels.Room) +
            " " +
            idx +
            ": " +
            camera.titolo
          );
        }
        return formService._upper(this.labels.Room) + " " + idx;
      };
    },
    // calcolo prezzo minore
    prezzo_min(obj) {
      if (obj && obj.length > 0) {
        var prezzo = this._sconto(obj[0]);
        for (let index = 0; index < obj.length; index++) {
          if (parseInt(this._sconto(obj[index])) < parseInt(prezzo)) {
            prezzo = this._sconto(obj[index]);
          }
        }
        return prezzo;
      } else {
        return "0,00";
      }
    },
    prezzo_totale(scontato = true) {
      var tot = 0;
      this.form.ospiti.map((el) => {
        if (el.tariffa && el.tariffa.prezzo) {
          tot += formService._toFloat(this.servizio_prezzo(el, scontato));
        }
      });
      return formService._toStr(tot);
    },
    servizio_prezzo(camera, scontato = true) {
      var srv = 0;
      camera.servizi.map((el) => {
        srv += formService._toFloat(el.prezzo);
      });
      return formService._toStr(formService._toFloat(scontato ? camera.tariffa.prezzo : camera.tariffa.prezzo_base) + srv);
    },
    // chiamata backend per confermare la prenotazione
    finalize(content, redirect = false) {
      apiService
        .post(
          "BE_prenotazione/finalize/" +
            this.$store.db +
            "/" +
            this.$store.idhotel +
            "/" +
            this.$store.lang,
          { ...this.form, content: content }
        )
        .then(() => {
          // tutto ok
          this.form.prenotata = true;
          this.$store.form = this.form;
          if (redirect) {
            this._goNext(redirect, this.form);
          }
        })
        .catch((err) => {
          if (err.msg && err.msg.length > 0) {
            for (let i = 0; i < err.msg.length; i++) {
              this.$store.err.push(err.msg[i]);
            }
            this._error();
          } else {
            this._error("ERR_GENERIC");
          }
        });
    },
    combo(resource, name = resource, param = "") {
      return new Promise((resolve) => {
        apiService
          .get("BE_booking/systab_be/" + resource + (param ? "/" + param : ""))
          .then((resp) => {
            if (resp[name] !== "undefined" && resp[name].length > 0) {
              resolve(resp[name]);
            }
            resolve([]);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    // selettore icone servizi
    _icon(servizio = "") {
      switch (servizio.toLowerCase()) {
        case "wifi":
          return ["fas", "wifi"];
        case "vent":
          return ["fas", "fan"];
        case "tv":
          return ["fas", "tv"];
        default:
          return ["fas", "circle"];
      }
    },
    _sconto(obj) {
      if (obj.sconto) {
        return (obj.prezzo - obj.prezzo * (obj.sconto / 100)).toFixed(2);
      } else {
        return obj.prezzo;
      }
    },
    // formattazione label + spazio
    _label(label = "") {
      if (label.trim() !== "") {
        return " ";
      }
      return "";
    },
    _goNext(route, formdata = false) {
      if (formdata !== false) {
        this.$store.form = formdata;
      }
      this.$router.push(route);
    },
    _fill(obj, data) {
      for (const key in data) {
        obj[key] = data[key];
      }
    },
    reset() {
      this._goNext(
        "/?RPC_db=" +
          this.$store.db +
          "&idhotel=" +
          this.$store.idhotel +
          "&lan=" +
          this.$store.lang,
        null
      );
    }
  }
};

export default bookingMixin;
