<template>
  <div class="errore">
    <div class="errore-box shadow">
      <div class="errore-title" :style="{ color: $theme.basecolor }">{{ labels.ERR ? labels.ERR : "ERRORE" }}:</div>
      <div>
        <div v-for="errore in this.$store.err" :key="errore" class="errore-text"><fa class="fa circle" :icon="['fas', 'circle']" />{{ errorText(errore) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import formService from "../services/form";
export default {
  data() {
    return {
      labels: { privacy: {} },
      def: {
        ERR_RPC: "Dati configurazione mancanti",
        ERR_IDHOTEL: "Dati configurazione mancanti",
        ERR_GENERIC: "Errore sconosciuto",
        ERR_PRENO: "Prenotazione non trovata",
        ERR_HOTEL: "Hotel non trovato",
        ERR_EXPIRED: "Riepilogo prenotazione scaduto"
      }
    };
  },
  mounted() {
    formService.init(this);
  },
  methods: {
    errorText(err) {
      if (this.labels[err]) {
        return this.labels[err];
      }
      if (this.def[err]) {
        return this.def[err];
      }
      return err;
    }
  }
};
</script>

<style lang="scss">
.errore {
  top: 7em;
  position: absolute;
  margin: 20px;
  padding: 0 5.6%;
  left: 0;
  right: 0;
  text-align: left;
  .errore-box {
    padding: 10px 20px;
    display: inline-block;
    background-color: white;
    min-width: 500px;
    max-width: 90%;
    min-height: 200px;
    .errore-title {
      border-bottom: 2px solid #b3b3b3;
      font-weight: bold;
      font-size: 1.2em;
    }
    .errore-text {
      padding-top: 10px;
      .circle {
        font-size: 0.7em;
        padding-bottom: 3px;
        margin-right: 7px;
      }
    }
  }
}
</style>
