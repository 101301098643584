<template>
  <div class="home" :style="{ '--base-dark': $theme.basedark, '--base-light': $theme.baselight, '--base-color': $theme.basecolor }">
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" style="min-width: 350px">
        <!-- -------------------------------------------- S I D E P A N E L -------------------------------------------- -->
        <div class="side-bar">
          <div class="sidepanel shadow disabled">
            <div class="row">
              <!-- ---------------- DATEPICKER ---------------- -->
              <div class="col-picker col-10 col-lg-11 col-xl-10">
                <div class="side picker" style="margin-top: 0">
                  <div class="fake-picker">
                    <div class="fake-icon">
                      <fa class="fa calendar" :style="{ color: $theme.basecolor }" :icon="['far', 'calendar-alt']" />
                    </div>
                    <div class="fake-arrivo">
                      <div class="label">{{ labels.datepicker ? labels.datepicker["check-in-label"] : "" }}</div>
                      <div class="date">{{ form.periodo.dataarrivo }}</div>
                    </div>
                    <div class="fake-icon">
                      <fa class="fa arrow" :style="{ color: $theme.basecolor }" :icon="['fas', 'chevron-right']" />
                    </div>
                    <div class="fake-partenza">
                      <div class="label">{{ labels.datepicker ? labels.datepicker["check-out-label"] : "" }}</div>
                      <div class="date">{{ form.periodo.datapartenza }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- NOTTI -------------------- -->
              <div class="col-notti col-2 col-lg-1 col-xl-2">
                <div class="side notti">
                  <fa class="fa moon" :icon="['far', 'moon']" :title="labels.notti" /><span class="label">{{ form.periodo.notti ? form.periodo.notti : 1 }}</span>
                </div>
              </div>
            </div>
            <!-- -------------------- TOTALI CAMERE -------------------- -->
            <div class="side totali ospiti">
              <div v-for="camera in form.ospiti" :key="camera.key" class="room">
                <div class="room-header">
                  <!-- ---- label ---- -->
                  <div class="room-preno">
                    <div class="room-title">
                      <div>{{ camera_label(camera) }}</div>
                      <div class="room-subtitle">{{ tariffa_label(camera) }}</div>
                    </div>
                    <div class="room-total">
                      <div class="total-price"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" /> {{ servizio_prezzo(camera) }}</div>
                    </div>
                  </div>
                  <div v-for="(srv, idx) in camera.servizi" :key="idx" class="room-servizi">
                    <div class="room-srv"><fa :class="'fa supp'" :icon="['fas', 'plus']" />{{ srv.titolo }}</div>
                    <div class="room-total">
                      <div class="total-price"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" /> {{ srv.prezzo }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- TOTALE -------------------- -->
              <div class="rooms-totale-wrapper">
                <div class="rooms-totlabel">
                  {{ labels.Tot }}
                </div>
                <div class="room-total">
                  <div class="total-price"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" /> {{ prezzo_totale() }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------- DESCRIZIONE PARAMETRIZZABILE DEL CLIENTE -------------------- -->
          <div v-show="this.$store.descsottototali != ''" class="sidepanel shadow">
            <div class="row">
              <div class="col-12 col-sm-12">
                <!-- ---------------- DESCRIZIONE ---------------- -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="side" style="margin-top: 0" v-html="this.$store.descsottototali"></div>
              </div>
            </div>
          </div>
          <!-- -------------------- BOTTONE AVANTI -------------------- -->
          <div class="side-doppio side-btn">
            <div class="btn-doppio bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="goBack()">
              <fa class="fa search indietro" :icon="['fas', 'chevron-left']" />
              {{ labels.btnIndietro }}
            </div>
            <div class="btn-doppio bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="nextPhase()">
              {{ labels.btnAvanti }}
              <fa class="fa search" :icon="['fas', 'chevron-right']" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-list col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content">
          <div v-for="servizio in serviziSupp" :id="servizio.key" :key="servizio.key" class="alloggio shadow" :disabled="servizio.disabled">
            <div v-if="servizio.visible && servizio.camere && prenotabili(servizio.camere).length > 0">
              <div class="alloggio-data shadow" :style="{ backgroundImage: 'url(' + servizio.sfondo + ') !important', borderRight: '4px solid ' + $theme.basecolor }">
                <div class="alloggio-dim">
                  <div class="row">
                    <div class="col-8 col-md-10">
                      <!-- -------------------- TITOLO -------------------- -->
                      <div class="titolo alloggio-content">{{ servizio.titolo }}</div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 12px">
                    <div class="col-8 col-md-10">
                      <!-- ---------------- DESCRIZIONE ---------------- -->
                      <div class="descrizione alloggio-content">{{ servizio.descrizione }}</div>
                    </div>
                    <div class="col-4 col-md-2 align-self-end">
                      <!-- -------------------- PREZZO -------------------- -->
                      <div class="prezzo alloggio-content">
                        <div class="prezzo-label">{{ labels.PartireDa }}</div>
                        <div class="prezzo-content">
                          <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                          {{ servizio.prezzo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------------------- P R E N O T A Z I O N I -------------------------------- -->
              <div class="card-righe">
                <div class="card-testata">
                  <div class="row">
                    <div class="col-12 col-sm-9 card-riga">
                      <div class="card-label">{{ labels.Preno }}</div>
                    </div>
                    <div class="col-12 col-sm-3 card-riga card-prezzo-header">
                      <div class="card-label">{{ labels.Prez }}</div>
                    </div>
                  </div>
                </div>
                <!-- -------------------- RIGA TARIFFA -------------------- -->
                <div class="tariffa">
                  <div v-for="preno in prenotabili(servizio.camere)" :key="preno.key" class="row">
                    <!-- ---- tipo tariffa ---- -->
                    <!-- ---- trattamento ---- -->
                    <div class="col-12 col-sm-9 card-riga card-label-counter">
                      <div class="card-label trattamento">
                        <div class="card-label">{{ camera_label(preno) }}</div>
                      </div>
                      <!-- ---- counter ---- -->
                      <div v-if="selected(preno, servizio) > 0" class="counter-wrapper" :style="{ backgroundColor: $theme.basecolor }">
                        <div class="card-counter" :title="labels.btnCestinoSrv">
                          <div class="counter-text">
                            {{ selected(preno, servizio) }}
                          </div>
                          <div class="counter-delete">
                            <fa class="fa times click" :icon="['fas', 'times']" @click="removeSelect(preno, servizio)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ---- prezzo ---- -->
                    <div class="col-12 col-sm-3 card-riga card-prezzo">
                      <div class="row align-self-center">
                        <div class="prezzo-div col-8 col-sm-12 col-md-12 col-lg-6 prezzo-main align-self-center counter-2">
                          <!-- ---- Prezzo ---- -->
                          <div class="prezzo-div-inner">
                            <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                            {{ tipoPrezzo(preno, servizio) }}
                          </div>
                          <!-- ---- counter 2 ---- -->
                          <div v-if="selected(preno, servizio) > 0" class="counter-wrapper-2">
                            <div class="card-counter" :title="labels.btnCestinoSrv" :style="{ backgroundColor: $theme.basecolor }">
                              <div class="counter-text">
                                {{ selected(preno, servizio) }}
                              </div>
                              <div class="counter-delete">
                                <fa class="fa times click" :icon="['fas', 'times']" @click="removeSelect(preno, servizio)" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- ---- bottone prenota ---- -->
                        <div class="prezzo-div col-4 col-sm-12 col-md-12 col-lg-6 prezzo-btn align-self-center counter-2">
                          <div class="btn-prenota cybtn" :style="{ backgroundColor: $theme.basecolor }" @click="select(preno, servizio)">
                            {{ labels.btnAdd }}
                            <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
                          </div>
                        </div>
                        <div class="prezzo-line col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="serviziSupp.length > 0" class="btn-footer">
          <div class="btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="nextPhase()">
            {{ labels.btnAvanti }}
            <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
  </div>
</template>

<script>
import CybFooter from "../components/cybfooter.vue";
import apiService from "../services/api";
import formService from "../services/form";
import baseMixin from "../mixins/base";

export default {
  components: {
    CybFooter
  },
  mixins: [baseMixin],
  data() {
    return {
      form: {
        periodo: {
          dataarrivo: "",
          datapartenza: "",
          notti: 0
        },
        ospiti: []
      },
      serviziSupp: [],
      labels: { privacy: {} }
    };
  },
  computed: {},
  mounted() {
    // chiamata backend per confermare i dati / recuperare altra roba
    if (this.$store.db && this.$store.idhotel) {
      this.$root.$on("services:reload", () => {
        this.loadContent(this.$store.lastpage === "camere");
      });
      formService.init(this);
      // paramentri mancanti
    } else {
      var err = [];
      if (!this.$store.db || !this.$store.idhotel) {
        err.push("ERR_RPC");
      }
      this._error(err);
    }
  },
  destroyed() {
    this.$root.$off("services:reload");
  },
  methods: {
    // ::::::::::::::::::::::::::::::: GESTIONE DATI ::::::::::::::::::::::::::::::::
    // gestione passaggio alla maschera form
    loadContent(apply = true) {
      this.$store.lastpage = "servizi";

      apiService.post("BE_servizi/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.$store.lang + "/" + this.$store.idlang, this.form).then((resp) => {
        this.serviziSupp = [];
        this.serviziSupp = [...resp.servizi];

        if (apply) {
          this.serviziSupp.map((srv) => {
            if (srv.required) {
              if (srv.index !== undefined) {
                // si applica ad una camera in particolare
                var idx = this.form.ospiti.findIndex((cam) => cam.key === srv.index);
                this.select(this.form.ospiti[idx], srv, true);
              } else {
                // si applica normalmente ai tipiCamera
                var camere = this.prenotabili(srv.camere);
                camere.map((cam) => {
                  this.select(cam, srv, true);
                });
              }
            }
          });
        }
      });
    },
    nextPhase() {
      this._goNext("/form", this.form);
    },
    goBack() {
      this._goNext("/?RPC_db=" + this.$store.db + "&idhotel=" + this.$store.idhotel + "&lan=" + this.$store.lang);
    },
    // :::::::::::::::::::::::::::::::: SERVIZI ::::::::::::::::::::::::::::::::
    // seleziona un servizio
    select(preno, servizio, locked = false) {
      preno.servizi.push({
        key: servizio.key,
        cod: servizio.cod,
        titolo: servizio.titolo,
        prezzo: this.tipoPrezzo(preno, servizio),
        tipo: servizio.tipo,
        locked: locked
      });
      this.form.ospiti = [...this.form.ospiti];
    },
    // de-seleziona un servizio
    removeSelect(preno, servizio) {
      // trovo l'ULTIMO servizio
      var index = formService.lastIndex(preno.servizi, (el) => el.key === servizio.key);
      // var index = [...preno.servizi].reverse().findIndex(el => el.key === servizio.key);

      preno.servizi.splice(index, 1);
      this.form.ospiti = [...this.form.ospiti];
    },
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
    tipoPrezzo(preno, servizio) {
      switch (servizio.tipo) {
        // A notte
        case "AN":
          return formService._toStr(formService._toFloat(servizio.prezzo) * this.form.periodo.notti);
        // A persone che occupano un letto
        case "TANP":
          return formService._toStr(formService._toFloat(servizio.prezzo) * this.letti(preno));
        // A persone che occupano un letto per notte
        case "APN":
          return formService._toStr(formService._toFloat(servizio.prezzo) * this.letti(preno) * this.form.periodo.notti);
        // prezzo fisso (TAN)
        default:
          return formService._toStr(servizio.prezzo);
      }
    },
    letti(preno) {
      return preno.adulti + preno.bambini.filter((el) => el >= preno.eta).length;
    },
    selected(preno, servizio) {
      return preno.servizi.filter((el) => el.key === servizio.key && el.locked === false).length;
    },
    prenotabili(camere) {
      return this.form.ospiti.filter((cam) => camere.includes(cam.codice));
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 593px) {
  .calendar,
  .arrow {
    font-size: 1.2em !important;
  }
}
.home {
  // ____________________________ SIDE-BAR ________________________________
  .side-bar {
    .sidepanel {
      .side.picker {
        height: 60px;
        .fake-picker {
          display: flex;
          justify-content: space-between;
          .fake-icon .fa.calendar {
            margin-left: 8px;
          }
          .fake-partenza,
          .fake-arrivo {
            flex-grow: 2;
            text-align: center;
          }
          .label {
            font-size: 14px;
          }
          .date {
            font-weight: bold;
          }
          .fake-icon {
            align-self: center;
            margin-top: 2px;
            &.fa {
              font-size: 24px;
            }
          }
        }
      }
      .side.totali .room {
        &:nth-last-child(2) {
          border-bottom: 1px solid #2a364d;
        }
        .room-header {
          display: flex;
          flex-direction: column;
          .room-preno {
            display: flex;
            .room-title {
              cursor: default !important;
              padding: 0 !important;
            }
          }
          .room-servizi {
            display: flex;
            .room-srv {
              font-size: 0.875em;
              .supp {
                font-size: 13px;
                padding-bottom: 2px;
                margin-right: 4px;
                margin-left: 4px;
              }
            }
            .room-total {
              .total-price {
                font-size: 0.875em;
              }
              min-width: 110px;
            }
          }
        }
      }
      .rooms-totale-wrapper {
        padding: 4px 6px 5px;
        display: flex;
        justify-content: space-between;
        .rooms-totlabel {
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .room-total .total-price {
          font-size: 1.1em;
        }
      }
      .room-total {
        min-width: 110px;
        display: flex !important;
        flex: 1 1 auto;
        justify-content: flex-end;
        .total-price {
          display: flex !important;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;

          color: #4dbf8f;
          font-size: 1.1em;
          font-weight: bold;
          align-self: center;
          .euro {
            font-size: 0.8em;
            padding-bottom: 1px;
            margin-right: 4px;
          }
          // gestione sconto
          &.discount-price {
            position: relative;
            color: #2a364d;
            opacity: 0.5;
            margin-right: 8px;
            margin-bottom: 0.5em;
            font-size: 0.9em;
            &::after {
              content: "";
              position: absolute;
              top: calc(50% - 1px);
              left: 0;
              right: 0;
              height: 2px;
              background-color: red;
              transform: rotate(5deg);
            }
            // background: linear-gradient(-25deg, transparent 45%, #2a364d, transparent 55%)
          }
        }
      }
    }
    .sidepanel.disabled {
      .fake-picker .fa {
        color: gray !important;
      }
      div:active,
      .fa:active {
        filter: brightness(1) !important;
      }
    }
  }
}
</style>
