<template>
  <datepicker
    ref="datepicker"
    :value="_value"
    :name="name"
    :format="format"
    :language="lingue[lang]"
    style="width:20px"
    wrapper-class="cyb-date-input"
    calendar-class="cyb-date-popup"
    monday-first
    full-month-name
    :placeholder="placeholder"
    :disabled="disabled"
    @input="_input"
  >
    <slot slot="afterDateInput">
      <fa class="date-icon fa calendar" :style="{ color: $theme.basecolor }" :icon="['far', 'calendar-alt']" @click="open()" />
    </slot>
  </datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { it, en, fr, de } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "CybDatePicker",
  components: {
    Datepicker
  },
  props: {
    value: { type: null, default: null },
    lang: { type: String, default: "ITA" },
    format: { type: String, default: "dd/MM/yyyy" },
    placeholder: { type: String, default: "" },
    name: { type: String, default: "" },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      lingue: {
        ITA: it,
        ING: en,
        FRA: fr,
        TED: de
      }
    };
  },
  computed: {
    _value() {
      return this.value ? moment(this.value, "DD/MM/YYYY").toDate() : "";
    }
  },
  methods: {
    open() {
      this.$refs.datepicker.$children[0].$emit("showCalendar");
    },
    _input(e) {
      this.$emit("input", moment(e).format("DD/MM/YYYY"));
    }
  }
};
</script>

<style lang="scss">
.cyb-date-input > div:first-of-type {
  display: flex;
  align-items: center;
  .date-icon {
    margin-right: 6px;
    cursor: pointer;
  }
  input {
    padding: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1199px) {
  .cyb-date-popup.vdp-datepicker__calendar {
    left:-270px;
  }
}
@media screen and (min-width: 1200px) {
  .cyb-date-popup.vdp-datepicker__calendar {
    left:0px;
  }
}

.cyb-date-popup.vdp-datepicker__calendar {
  header {
    margin-top: 3px;
    span {
      margin: 3px;
      height: 35px !important;
      font-weight: bold;
      &:nth-of-type(2) {
        width: 209px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          animation: blink .5s ease-in-out infinite alternate-reverse;
          padding-left: 10px;
          padding-top: 1px;
          content: "🔍";
        }
      }
      &:hover {
        // background-color: transparent !important;
        background-color: var(--base-color) !important;
        color: white;
        &::after {
          animation: none;
        }
      }
    }
    .prev,
    .next {
      color: red;
      border: 2px solid var(--base-color);
      width: 35px !important;
      &:hover {
        background-color: transparent !important;
      }
      &:active {
        border-color: var(--base-dark);
      }
    }
    .prev {
      &:active::after {
        border-right-color: var(--base-dark);
      }
      &:after {
        border-right-color: var(--base-color);
      }
    }
    .next {
      &:active::after {
        border-left-color: var(--base-dark);
      }
      &:after {
        border-left-color: var(--base-color);
      }
    }
  }
  div {
    padding: 25px;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .cell {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: -0.5px;
      padding: 0;
      width: 36px;
      &.day-header {
        height: 20px;
      }
      &.day {
        border: 1px solid lightgray;
        height: 36px;
        &:hover,
        &.selected {
          border-color: lightgray !important;
        }
      }
    }
  }
  .cell {
    &.day,
    &.month,
    &.year {
      font-size: 14px;
      font-weight: bold;
      &:hover,
      &.selected {
        border-color: transparent !important;
        background-color: var(--base-color);
        color: white;
      }
      &.blank {
        border: none !important;
        background-color: transparent !important;
      }
    }
  }
}
</style>
