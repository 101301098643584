<template>
  <div class="cyb-hotel-datepicker">
    <div class="date-picker">
      <div class="date-icon">
        <fa class="fa calendar" :style="{ color: $theme.basecolor }" :icon="['far', 'calendar-alt']" />
      </div>
      <div class="date-arrivo">
        <div class="label">{{ labels["check-in-label"] }}</div>
      </div>
      <div class="date-icon">
        <fa class="fa arrow" :style="{ color: $theme.basecolor }" :icon="['fas', 'chevron-right']" />
      </div>
      <div class="date-partenza">
        <div class="label">{{ labels["check-out-label"] }}</div>
      </div>
    </div>
    <hotel-date-picker ref="hoteldate" :class="$theme.colorclass" format="DD/MM/YYYY" :display-clear-button="false" :show-single-month="true" :i18n="labels" :starting-date-value="oggi()" :ending-date-value="domani()" @period-selected="getDate">
    </hotel-date-picker>
  </div>
</template>

<script>
import HotelDatePicker from "vue-hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
import moment from "moment";

var labelHotelDatepicker = {
  night: "Notte",
  nights: "Notti",
  "day-names": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  "check-in": "Arrivo",
  "check-out": "Partenza",
  "check-in-label": "Data di arrivo",
  "check-out-label": "Data di partenza",
  "month-names": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
  tooltip: {
    minimumRequiredPeriod: "Minimo %{minNightInPeriod} %{night}"
  }
};

export default {
  name: "CybHotelDatePicker",
  components: {
    HotelDatePicker
  },
  props: {
    labels: {
      type: Object,
      default: () => {
        return labelHotelDatepicker;
      }
    }
  },
  data() {
    return {
      datahotel: {
        dataarrivo: "",
        datapartenza: "",
        notti: 0
      }
    };
  },
  mounted() {
    this.getDate(null, this.oggi(), this.domani());
  },
  unmounted() {
    this.$off("input");
  },
  methods: {
    oggi() {
      if (this.$store.form && this.$store.form.periodo.dataarrivo) {
        return moment(this.$store.form.periodo.dataarrivo, "DD/MM/YYYY").toDate();
      }
      if (this.$postData && this.$postData.da_data) {
        return moment(this.$postData.da_data, "DD/MM/YYYY").toDate();
      }
      return moment().toDate();
    },
    domani() {
      if (this.$store.form && this.$store.form.periodo.datapartenza) {
        return moment(this.$store.form.periodo.datapartenza, "DD/MM/YYYY").toDate();
      }
      if (this.$postData && this.$postData.a_data) {
        return moment(this.$postData.a_data, "DD/MM/YYYY").toDate();
      }
      return moment().add(1, "day").toDate();
    },
    getDate(event, start, end) {
      this.datahotel.dataarrivo = moment(start).format("DD/MM/YYYY");
      this.datahotel.datapartenza = moment(end).format("DD/MM/YYYY");
      this.datahotel.notti = moment(end).diff(start, "days");
      this.$emit("input", this.datahotel);
    },
    clearDate() {
      this.datahotel.dataarrivo = "";
      this.datahotel.datapartenza = "";
      this.datahotel.notti = 0;
      this.$emit("input", this.datahotel);
    }
  }
};
</script>

<style lang="scss" scoped>
// CSS mobile
@media (max-width: 767px) {
  ::v-deep .vhd__datepicker.vhd__datepicker--open {
    max-height: 85%;
    top: 7.5em;
    // scritte inutili
    .vhd__datepicker__input,
    .vhd__datepicker__tooltip--mobile {
      display: none;
    }
    // header - bottoni
    .vhd__datepicker__header-mobile .vhd__datepicker__month-button {
      margin-top: 8px;
      width: 2em;
      height: 3.5em;
      &.vhd__datepicker__month-button--prev {
        margin-left: 2.7em;
      }
      &.vhd__datepicker__month-button--next {
        margin-right: 2.7em;
      }
    }
    .vhd__datepicker__months {
      margin-top: 0em !important;
      overflow: hidden !important;
      height: 100% !important;
      .vhd__datepicker__month {
        .vhd__datepicker__month-name {
          font-size: 18px;
          padding-top: 12px;
        }
        .vhd__datepicker__week-row.vhd__hide-up-to-tablet {
          margin-top: 10px;
          display: block !important;
          box-shadow: none;
        }
      }
    }
  }
  // bottone x
  ::v-deep .vhd__datepicker__close-button.vhd__hide-on-desktop {
    transition: top 0.2s ease-in-out;
    top: 7.7em;
    right: 0.5em;
    // border: 2px solid black;
    font-size: 16px;
    width: 2em !important;
    height: 3.5em !important;
    i {
      font-weight: 100 !important;
      color: black;
      padding: 0px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.9em;
    }
  }
}
@media (max-width: 576px) {
  ::v-deep .vhd__datepicker.vhd__datepicker--open {
    max-height: 80%;
    top: 10.8em;
  }
  ::v-deep .vhd__datepicker__close-button.vhd__hide-on-desktop {
    top: 11em;
  }
}
// CSS standard
@media (max-width: 593px) {
  .calendar,
  .arrow {
    font-size: 1.2em !important;
  }
}
.cyb-hotel-datepicker {
  position: static;
  height: 48px;
}
::v-deep .vhd__datepicker__wrapper {
  position: relative;
  background: none; // icona datepicker
  margin-top: -30px;
  top: -18px;
  height: 48px;
  .vhd__datepicker__dummy-wrapper {
    border: none;
    padding: none;
    position: absolute;
    padding-top: 21px;
    .vhd__datepicker__input {
      font-size: 1em;
      text-align: center;
      padding: 0;
      font-weight: bold;
      height: inherit;
      line-height: inherit;
      word-spacing: inherit;
      &:first-child {
        background: none; // icona freccia
        padding-left: 26px;
      }
      &:last-child {
        padding-left: 12px;
      }
    }
  }
  .vhd__datepicker__tooltip {
    padding: 2px 5px;
  }
  .vhd__datepicker {
    margin-top: -5px !important;
    &.vhd__datepicker--open {
      .vhd__datepicker__month-button {
        background: transparent;
        border: none;
        &::after {
          color: #00ca9d !important;
          font-size: 2em;
          content: "›";
        }
      }
    }
  }
  // popup
  // TODO da fare quando si sa che colori mettere
  // .vhd__datepicker {
  //   // oggi
  //   .vhd__datepicker__month-day--today {}
  //   // checkin
  //   .vhd__datepicker__month-day--first-day-selected {}
  //   // middle
  //   .vhd__datepicker__month-day--selected {}
  //   // checkout
  //   .vhd__datepicker__month-day--last-day-selected {}
  // }
}
::v-deep .date-picker {
  display: flex;
  justify-content: space-between;
  height: 48px;
  .date-icon .fa.calendar {
    margin-left: 8px;
  }
  .date-partenza,
  .date-arrivo {
    flex-grow: 2;
    text-align: center;
  }
  .label {
    font-size: 14px;
  }
  .date {
    font-weight: bold;
  }
  .date-icon {
    align-self: center;
    &.fa {
      font-size: 24px;
    }
  }
}
// :::::::::::::::::::::::::::::::: COLORI ::::::::::::::::::::::::::::::::
// ---------------- base-gray --------------------
// ::v-deep .vhd__datepicker__month-button {
//   filter: saturate(0);
//   border-width: 2px;
//   &:hover {
//     opacity: 1;
//   }
//   &:active {
//     filter: saturate(0) brightness(0.5) !important;
//   }
// }
// ---------------- custom-vue --------------------
::v-deep .custom-vue .vhd__datepicker__month-button {
  // filter: hue-rotate(-15deg) saturate(0.57);
  filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1382%) hue-rotate(101deg) brightness(97%) contrast(80%);
  &:active {
    filter: brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1382%) hue-rotate(101deg) brightness(97%) contrast(80%) brightness(0.5) !important;
  }
}
// ---------------- custom-red --------------------
::v-deep .custom-red .vhd__datepicker__month-button {
  filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(6761%) hue-rotate(6deg) brightness(108%) contrast(131%);
  &:active {
    filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(6761%) hue-rotate(6deg) brightness(108%) contrast(131%) brightness(0.5) !important;
  }
}
// ---------------- custom-rose --------------------
::v-deep .custom-rose .vhd__datepicker__month-button {
  filter: brightness(0) invert(19%) sepia(93%) saturate(7475%) hue-rotate(324deg) brightness(102%) contrast(104%);
  &:active {
    filter: brightness(0) invert(19%) sepia(93%) saturate(7475%) hue-rotate(324deg) brightness(102%) contrast(104%) brightness(0.5) !important;
  }
}
// ---------------- custom-magenta --------------------
::v-deep .custom-magenta .vhd__datepicker__month-button {
  filter: brightness(0) invert(31%) sepia(96%) saturate(6017%) hue-rotate(294deg) brightness(113%) contrast(130%);
  &:active {
    filter: brightness(0) invert(31%) sepia(96%) saturate(6017%) hue-rotate(294deg) brightness(113%) contrast(130%) brightness(0.5) !important;
  }
}
// ---------------- custom-violet --------------------
::v-deep .custom-violet .vhd__datepicker__month-button {
  filter: brightness(0) invert(12%) sepia(100%) saturate(7497%) hue-rotate(272deg) brightness(100%) contrast(124%);
  &:active {
    filter: brightness(0) invert(12%) sepia(100%) saturate(7497%) hue-rotate(272deg) brightness(100%) contrast(124%) brightness(0.5) !important;
  }
}
// ---------------- custom-blue --------------------
::v-deep .custom-blue .vhd__datepicker__month-button {
  filter: brightness(0) invert(9%) sepia(100%) saturate(6560%) hue-rotate(248deg) brightness(91%) contrast(147%);
  &:active {
    filter: brightness(0) invert(9%) sepia(100%) saturate(6560%) hue-rotate(248deg) brightness(91%) contrast(147%) brightness(0.5) !important;
  }
}
// ---------------- custom-azure --------------------
::v-deep .custom-azure .vhd__datepicker__month-button {
  filter: brightness(0) invert(29%) sepia(85%) saturate(2482%) hue-rotate(199deg) brightness(105%) contrast(104%);
  &:active {
    filter: brightness(0) invert(29%) sepia(85%) saturate(2482%) hue-rotate(199deg) brightness(105%) contrast(104%) brightness(0.5) !important;
  }
}
// ---------------- custom-cyan --------------------
::v-deep .custom-cyan .vhd__datepicker__month-button {
  filter: brightness(0) invert(83%) sepia(64%) saturate(2129%) hue-rotate(117deg) brightness(103%) contrast(103%);
  &:active {
    filter: brightness(0) invert(83%) sepia(64%) saturate(2129%) hue-rotate(117deg) brightness(103%) contrast(103%) brightness(0.5) !important;
  }
}
// ---------------- custom-mint --------------------
::v-deep .custom-mint .vhd__datepicker__month-button {
  filter: brightness(0) invert(89%) sepia(26%) saturate(6635%) hue-rotate(90deg) brightness(100%) contrast(104%);
  &:active {
    filter: brightness(0) invert(89%) sepia(26%) saturate(6635%) hue-rotate(90deg) brightness(100%) contrast(104%) brightness(0.5) !important;
  }
}
// ---------------- custom-green --------------------
::v-deep .custom-green .vhd__datepicker__month-button {
  filter: brightness(0) invert(70%) sepia(82%) saturate(5584%) hue-rotate(92deg) brightness(128%) contrast(118%);
  &:active {
    filter: brightness(0) invert(70%) sepia(82%) saturate(5584%) hue-rotate(92deg) brightness(128%) contrast(118%) brightness(0.5) !important;
  }
}
// ---------------- custom-lime --------------------
::v-deep .custom-lime .vhd__datepicker__month-button {
  filter: brightness(0) invert(72%) sepia(78%) saturate(963%) hue-rotate(40deg) brightness(110%) contrast(114%);
  &:active {
    filter: brightness(0) invert(72%) sepia(78%) saturate(963%) hue-rotate(40deg) brightness(110%) contrast(114%) brightness(0.5) !important;
  }
}
// ---------------- custom-yellow --------------------
::v-deep .custom-yellow .vhd__datepicker__month-button {
  filter: brightness(0) invert(82%) sepia(71%) saturate(616%) hue-rotate(1deg) brightness(108%) contrast(102%);
  &:active {
    filter: brightness(0) invert(82%) sepia(71%) saturate(616%) hue-rotate(1deg) brightness(108%) contrast(102%) brightness(0.5) !important;
  }
}
// ---------------- custom-orange --------------------
::v-deep .custom-orange .vhd__datepicker__month-button {
  filter: brightness(0) invert(61%) sepia(45%) saturate(5196%) hue-rotate(360deg) brightness(101%) contrast(107%);
  &:active {
    filter: brightness(0) invert(61%) sepia(45%) saturate(5196%) hue-rotate(360deg) brightness(101%) contrast(107%) brightness(0.5) !important;
  }
}
</style>
