<template>
  <div>
    <vue-modaltor :visible="visible" :bg-overlay="'#0005'" :bg-panel="'white'" :resize-width="{ 1366: '80%', 768: '90%', 576: '100%' }" @hideModal="$emit('modalClose')">
      <template #body>
        <div v-if="modaldata" class="row modal-container">
          <!-- immagini -->
          <div v-if="modaldata.immagini" class="col-md-12 col-lg-7 modal-slide">
            <agile :nav-buttons="listaImg">
              <img v-for="(img, idx) in modaldata.immagini" :key="idx" class="slide-img" :src="img" />
              <template v-if="listaImg" slot="prevButton"><fa class="fa chevron-left" :icon="['fas', 'chevron-left']" /></template>
              <template v-if="listaImg" slot="nextButton"><fa class="fa chevron-right" :icon="['fas', 'chevron-right']" /></template>
            </agile>
          </div>
          <div :class="'col-md-12 ' + (modaldata.immagini ? 'col-lg-5 with-img' : 'col-lg-12') + ' modal-data'">
            <!-- titolo -->
            <div class="row data-item data-title" :style="{ backgroundColor: $theme.basecolor }">
              <div>{{ modaldata.titolo }}</div>
              <div class="data-close" @click="$emit('modalClose')"><fa class="fa times" :icon="['fas', 'times']" /></div>
            </div>
            <!-- descrizione -->
            <div class="data-item data-desc">{{ modaldata.descrizione }}</div>
            <!-- dati custom -->
            <slot></slot>
          </div>
        </div>
      </template>
    </vue-modaltor>
  </div>
</template>

<script>
export default {
  name: "CybModal",
  props: {
    modaldata: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false }
  },
  computed: {
    listaImg() {
      if (this.modaldata && this.modaldata.immagini) {
        return this.modaldata.immagini.length > 1;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.modaltor {
  .modaltor__header {
    display: none;
  }
  .modaltor__content {
    color: #2a364d;
    font-size: 16px;
    .modal-container {
      margin: 0;
      .modal-slide {
        padding: 0;
        .slide-img {
          height: calc(100vh - 70px);
          background-color: #000;
          object-fit: cover;
          object-position: center;
          width: 100%;
        }
        .agile__nav-button {
          background: transparent;
          border: none;
          color: #fff;
          cursor: pointer;
          font-size: 24px;
          height: 100%;
          position: absolute;
          top: 0;
          transition-duration: 0.3s;
          width: 40px;
          &:hover {
            background-color: rgba(#000, 0.4);
            opacity: 1;
          }
          &.agile__nav-button--prev {
            left: 0;
          }

          &.agile__nav-button--next {
            right: 0;
          }
        }
        .agile__dots {
          bottom: 0;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          .agile__dot {
            margin: 0 10px;
            button {
              background-color: transparent;
              border: 1px solid #fff;
              border-radius: 50%;
              cursor: pointer;
              display: block;
              height: 10px;
              font-size: 0;
              line-height: 0;
              margin: 0;
              padding: 0;
              transition-duration: 0.3s;
              width: 10px;
            }
            &.agile__dot--current,
            &:hover button {
              border-radius: 50%;
              background-color: #fff;
            }
          }
        }
      }
      .modal-data {
        padding-bottom: 10px;
        overflow: auto;
        &.with-img {
          height: calc(100vh - 70px);
        }
        .data-item {
          margin-bottom: 10px;
          &.data-title {
            color: white;
            font-weight: bold;
            padding: 5px 15px;
            display: flex;
            justify-content: space-between;
            .data-close .fa {
              cursor: pointer;
              &:active {
                color: #222;
              }
            }
          }
          &.data-servizi {
            .fa-circle {
              margin-left: 1px;
              margin-right: 3px;
              padding: 3px;
            }
          }
          &.data-policy {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
</style>
