<template>
  <div class="home" :style="{ '--base-dark': $theme.basedark, '--base-light': $theme.baselight, '--base-color': $theme.basecolor }">
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="content-list col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content-grazie" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.GrazieCheck }}</div>
          <div class="wrapper">
            <div class="row">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div style="margin-bottom: 1.8em" v-html="text"></div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="subtitle" v-html="policy"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
  </div>
</template>

<script>
import CybFooter from "../components/cybfooter.vue";
import formService from "../services/form";
import apiService from "../services/api.js";
import baseMixin from "../mixins/base";

export default {
  components: {
    CybFooter
  },
  mixins: [baseMixin],
  data() {
    return {
      form: {
        periodo: {
          dataarrivo: "",
          datapartenza: "",
          notti: 0
        },
        ospiti: []
      },
      text: "",
      policy: "",
      labels: { privacy: {} }
    };
  },
  mounted() {
    this.$root.$on("thanks:reload", () => {
      this.loadContent();
    });
    formService.init(this);
    // this.loadContent();
  },
  destroyed() {
    this.$root.$off("thanks:reload");
  },
  methods: {
    // ::::::::::::::::::::::::::::::::: GESTIONE DATI :::::::::::::::::::::::::::::::::
    loadContent() {
      // chiamata backend per recuperare roba
      if (this.$store.db && this.$store.idhotel) {
        apiService.post("BE_grazie/checkin/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.$store.lang + "/" + this.$store.idlang, this.form).then((resp) => {
          if (resp) {
            this.text = resp.text;
            this.policy = resp.policy;
          }
        });

        // paramentri mancanti
      } else {
        var err = [];
        if (!this.$store.db || !this.$store.idhotel) {
          err.push("ERR_RPC");
        }
        this._error(err);
      }
    }
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
  }
};
</script>

<style lang="scss">
.home {
  // ____________________________ CONTENT ________________________________
  .content-grazie {
    color: #2a364d;
    background-color: white;
    margin: 20px 7px;
    position: relative;
    z-index: 1;
    // padding: 0 10% 2em;
    .title {
      padding: 0.5em 8.8%;
      font-weight: bold;
      margin: 0 0 1em;
      background-color: #c3c9df;
    }
    .wrapper {
      padding: 2em 10% 4em;
      .subtitle {
        font-size: 0.9em;
        display: block;
      }
      .text {
        color: #585858;
        font-size: 0.8em;
      }
      .cyb-input {
        margin: 5px 0;
        border: 1px solid #585858;
        input,
        textarea,
        select {
          background-color: transparent;
          padding: 4px 10px;
          outline: none !important;
          border: none;
          width: 100%;
          &::placeholder {
            opacity: 0.6;
          }
          &[disabled] {
            color: #8d8d8d !important;
            opacity: 1 !important;
            border-color: #8d8d8d !important;
          }
        }
        &.disabled {
          background-color: #e9e9e9 !important;
        }
        textarea {
          margin-bottom: -7px;
        }
        &.cyb-select {
          padding-right: 4px;
          select {
            padding: 6px;
            &:invalid {
              color: #a5a5a5;
            }
            option {
              color: #2a364d;
            }
          }
        }
      }
    }
    .cyb-errore {
      margin-left: 2px;
      color: #e6173a;
      font-size: 0.9em;
      font-style: italic;
    }
  }
}
</style>
