import Vue from "vue";

const formService = {
  _app: null,
  init(app, events = true) {
    this._app = app;
    if (events) {
      // gestione label / lingua
      this._app.$root.$on("lang:change", (label) => {
        // this._app.labels = label;
        Vue.set(this._app, "labels", label);
      });
      this._app.$root.$emit("lang:request", this._app.$store.lang);
      // filtri persistenti
      if (this._app.$store.form) {
        this._app.form = this._app.$store.form;
      }
    }
  },
  brightness(hex, value) {
    hex = hex.replace(/^\s*#|\s*$/g, "");
    var r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);
    var red, green, blue;
    if (value >= 0) {
      red = (0 | ((1 << 8) + r + ((256 - r) * value) / 100))
        .toString(16)
        .substr(1);
      green = (0 | ((1 << 8) + g + ((256 - g) * value) / 100))
        .toString(16)
        .substr(1);
      blue = (0 | ((1 << 8) + b + ((256 - b) * value) / 100))
        .toString(16)
        .substr(1);
    } else {
      red = (0 | ((1 << 8) + (r * (100 + value)) / 100)).toString(16).substr(1);
      green = (0 | ((1 << 8) + (g * (100 + value)) / 100))
        .toString(16)
        .substr(1);
      blue = (0 | ((1 << 8) + (b * (100 + value)) / 100))
        .toString(16)
        .substr(1);
    }
    return "#" + red + green + blue;
  },
  lastIndex(array, condition) {
    var index = [...array].reverse().findIndex(condition);
    return array.length - (index + 1);
  },
  _upper(str) {
    if (str) {
      return str.toUpperCase();
    }
    return str;
  },
  _toStr(float) {
    float = "" + parseFloat(float).toFixed(2);
    return float.replace(".", ",");
  },
  _toFloat(str) {
    if (typeof str === "number") {
      return parseFloat(str);
    } else {
      return parseFloat(str.replace(",", "."));
    }
  }
};
export default formService;
