import Axios from "axios";
import Queue from "./queue";

const apiService = {
  baseurl: null,
  _csrfRequired: ["post", "put", "patch", "delete", "options"],
  _app: null,
  _queue: null,
  _enableQueuing: false,
  init(app) {
    apiService.baseurl = app.$config.server_url;
    apiService._app = app;
  },
  startQueue() {
    if (!apiService._enableQueuing) {
      apiService._queue = new Queue();
      apiService._enableQueuing = true;
    }
  },
  flushQueue() {
    if (apiService._enableQueuing) {
      apiService._enableQueuing = false;
      apiService._queue.dequeue();
    }
  },
  _preCall(method, url, data) {
    if (!apiService.baseurl) {
      var err = new Error("No apiService.baseurl set");
      return {
        error: err.message,
        system: err.message,
        trace: err.stack
      };
    }
    var config = {
      method: method,
      url: apiService.baseurl + "/" + url,
      headers: {},
      data: data
    };

    config.headers.Authorization = "Bearer " + ""; // authService.getSession()
    if (
      apiService._csrfRequired.indexOf(method.toLowerCase()) > -1 &&
      !data.csrf
    ) {
      config.headers["X-CSRF-TOKEN"] = ""; // authService.getCsrf()
    }
    return config;
  },
  _postCall(config, response) {
    if (response) {
      if (
        apiService._csrfRequired.indexOf(config.method.toLowerCase()) > -1 &&
        response.headers &&
        response.headers["x-csrf-token"]
      ) {
        // authService.setCsrf(response.headers["x-csrf-token"]);
      }
      if (response.status === 401) {
        // eventService.emit("auth:logout", { nosession: true });
      }
      if (response.data) {
        if (response.data.system_messages && this._app) {
          apiService._app.$root.$emit(
            "update:message",
            response.data.system_messages
          );
        }
        if (response.status) {
          response.data.status = response.status;
        }
        return response.data;
      }
      return response;
    }
    return false;
  },
  call(method, url, data, instant) {
    return apiService._enableQueuing && !instant
      ? apiService._queue.enqueue(() => apiService._call(method, url, data))
      : apiService._call(method, url, data);
  },
  _call(method, url, data) {
    return new Promise((resolve, reject) => {
      var config = apiService._preCall(method, url, data);
      if (config.error) {
        reject({
          status: -1,
          error: config.error,
          system: "NO_BASEURL",
          ...(config.trace && {
            trace: config.trace.split("\n").map(el => {
              return String(el).trim();
            })
          })
        });
      } else {
        Axios(config)
          .then(resp => {
            var response = apiService._postCall(config, resp);
            resolve(response);
          })
          .catch(resp => {
            var response = apiService._postCall(config, resp.response);
            reject(response);
          });
      }
    });
  },
  get(url, data) {
    if (data && typeof data === "object") {
      url += "?" + apiService._serializeObject(data);
    }
    return apiService.call("get", url, data);
  },
  post(url, data) {
    return apiService.call("post", url, data);
  },
  put(url, data) {
    return apiService.call("put", url, data);
  },
  delete(url, data) {
    return apiService.call("delete", url, data);
  },
  patch(url, data) {
    return apiService.call("patch", url, data);
  },
  options(url, data) {
    return apiService.call("options", url, data);
  },
  instant_get(url, data) {
    if (data && typeof data === "object") {
      url += "?" + apiService._serializeObject(data);
    }
    return apiService.call("get", url, data, true);
  },
  instant_post(url, data) {
    return apiService.call("post", url, data, true);
  },
  instant_put(url, data) {
    return apiService.call("put", url, data, true);
  },
  instant_delete(url, data) {
    return apiService.call("delete", url, data, true);
  },
  instant_patch(url, data) {
    return apiService.call("patch", url, data, true);
  },
  instant_options(url, data) {
    return apiService.call("options", url, data, true);
  },
  _serializeObject(data, prefix) {
    var str = [];
    if (data) {
      for (var index in data) {
        // hasOwnProperty esclude le proprietà ereditate nel caso in cui venga passato un oggetto VUE
        if (Object.prototype.hasOwnProperty.call(data, "index")) {
          if (typeof data[index] === "object") {
            str.push(apiService._serializeObject(data[index], index));
          } else {
            if (prefix) {
              str.push(
                encodeURIComponent(prefix) +
                  ("[" + index + "]") +
                  "=" +
                  encodeURIComponent(data[index])
              );
            } else {
              str.push(
                encodeURIComponent(index) +
                  "=" +
                  encodeURIComponent(data[index])
              );
            }
          }
        }
      }
    }
    return str.join("&");
  }
};

export default apiService;
