<template>
  <div class="home" :style="{ '--base-dark': $theme.basedark, '--base-light': $theme.baselight, '--base-color': $theme.basecolor }">
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" style="min-width: 350px">
        <!-- -------------------------------------------- S I D E P A N E L -------------------------------------------- -->
        <div class="side-bar">
          <div class="sidepanel shadow disabled">
            <div class="row">
              <!-- ---------------- DATEPICKER ---------------- -->
              <div class="col-picker col-10 col-lg-11 col-xl-10">
                <div class="side picker" style="margin-top: 0">
                  <div class="fake-picker">
                    <div class="fake-icon">
                      <fa class="fa calendar" :style="{ color: $theme.basecolor }" :icon="['far', 'calendar-alt']" />
                    </div>
                    <div class="fake-arrivo">
                      <div class="label">{{ labels.datepicker ? labels.datepicker["check-in-label"] : "" }}</div>
                      <div class="date">{{ dataArrivo }}</div>
                    </div>
                    <div class="fake-icon">
                      <fa class="fa arrow" :style="{ color: $theme.basecolor }" :icon="['fas', 'chevron-right']" />
                    </div>
                    <div class="fake-partenza">
                      <div class="label">{{ labels.datepicker ? labels.datepicker["check-out-label"] : "" }}</div>
                      <div class="date">{{ dataPartenza }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- NOTTI -------------------- -->
              <div class="col-notti col-2 col-lg-1 col-xl-2">
                <div class="side notti">
                  <fa class="fa moon" :icon="['far', 'moon']" :title="labels.notti" /><span class="label">{{ form.periodo.notti ? form.periodo.notti : 1 }}</span>
                </div>
              </div>
            </div>
            <!-- -------------------- TOTALI CAMERE -------------------- -->
            <div class="side totali ospiti">
              <div v-for="camera in form.ospiti" :key="camera.key" class="room">
                <div class="room-header">
                  <!-- ---- label ---- -->
                  <div class="room-preno">
                    <div class="room-title">
                      <div>{{ camera_label(camera) }}</div>
                      <div class="room-subtitle">{{ tariffa_label(camera) }}</div>
                    </div>
                    <div class="room-total">
                      <div v-if="_scontata(camera.tariffa)" class="total-price discount-price">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ servizio_prezzo(camera, false) }}
                      </div>
                      <div class="total-price">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ servizio_prezzo(camera) }}
                      </div>
                    </div>
                  </div>
                  <div v-for="(srv, idx) in camera.servizi" :key="idx" class="room-servizi">
                    <div class="room-srv"><fa :class="'fa supp'" :icon="['fas', 'plus']" />{{ srv.titolo }}</div>
                    <div class="room-total">
                      <div class="total-price"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" /> {{ srv.prezzo }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- TOTALE -------------------- -->
              <div class="rooms-totale-wrapper">
                <div class="rooms-totlabel">
                  {{ labels.Tot }}
                </div>
                <div class="room-total">
                  <div class="total-price"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" /> {{ prezzo_totale() }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------- DESCRIZIONE PARAMETRIZZABILE DEL CLIENTE -------------------- -->
          <div v-show="this.$store.descsottototali != ''" class="sidepanel shadow">
            <div class="row">
              <div class="col-12 col-sm-12">
                <!-- ---------------- DESCRIZIONE ---------------- -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="side" style="margin-top: 0" v-html="this.$store.descsottototali"></div>
              </div>
            </div>
          </div>
          <!-- -------------------- BOTTONE AVANTI -------------------- -->
          <!-- <div class="side-btn">
            <div class="bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="finalize()">
              {{ labels.btnConferma }}
              <fa class="fa search" :icon="['fas', 'chevron-right']" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="content-list col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content-grazie" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.GraziePreno }}</div>
          <div class="wrapper">
            <div class="row">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div style="margin-bottom: 1.8em" v-html="text"></div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="subtitle" v-html="policy"></div>
            </div>
          </div>
        </div>
        <div class="btn-footer">
          <div class="btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="reset()">
            {{ labels.btnTorna }}
            <!-- <fa class="fa arrow" :icon="['fas', 'chevron-right']" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
  </div>
</template>

<script>
import CybFooter from "../components/cybfooter.vue";
import formService from "../services/form";
import apiService from "../services/api.js";
import baseMixin from "../mixins/base";

export default {
  components: {
    CybFooter
  },
  mixins: [baseMixin],
  data() {
    return {
      form: {
        periodo: {
          dataarrivo: "",
          datapartenza: "",
          notti: 0
        },
        ospiti: []
      },
      content: {},
      text: "",
      policy: "",
      labels: { privacy: {} }
    };
  },
  computed: {
    dataArrivo() {
      return this.form.periodo.dataarrivo ? this.form.periodo.dataarrivo : this.labels.datepicker !== undefined ? this.labels.datepicker["check-in"] : "";
    },
    dataPartenza() {
      return this.form.periodo.datapartenza ? this.form.periodo.datapartenza : this.labels.datepicker !== undefined ? this.labels.datepicker["check-out"] : "";
    }
  },
  mounted() {
    // gestione rientro da nexi
    if (typeof this.$route.query.token !== "undefined" && this.$route.query.token.length === 11) {
      apiService.post("BE_form/decodenexi/" + this.$route.query.token, this.$route.query).then((resp) => {
        this.content = resp.data.content;
        this.content.codtrans = resp.codtrans;
        this._fill(this.$hotel, resp.data.hotel);
        this._fill(this.$theme, resp.data.theme);
        this._fill(this.$store, resp.data.store);
        this.$store.form = resp.data.form;
        formService.init(this);

        // gestione differente a seconda dell'esito del pagamento
        switch (this.$route.query.esito) {
          case "OK":
            this.finalize(this.content);
            break;
          case "KO":
          case "ERRORE":
          case "ANNULLO":
            var query = this.$route.fullPath.split("?");
            query.shift();
            this._goNext("/form?" + query.join());
            break;
        }
      });
    } else {
      formService.init(this);
    }

    this.$root.$on("thanks:reload", () => {
      this.loadContent();
    });
  },
  destroyed() {
    this.$root.$off("thanks:reload");
  },
  methods: {
    // ::::::::::::::::::::::::::::::::: GESTIONE DATI :::::::::::::::::::::::::::::::::
    loadContent() {
      // chiamata backend per recuperare roba
      if (this.$store.db && this.$store.idhotel) {
        apiService.post("BE_grazie/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.$store.lang + "/" + this.$store.idlang, this.form).then((resp) => {
          if (resp) {
            this.text = resp.text;
            this.policy = resp.policy;
          }
        });

        // paramentri mancanti
      } else {
        var err = [];
        if (!this.$store.db || !this.$store.idhotel) {
          err.push("ERR_RPC");
        }
        this._error(err);
      }
    },
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
    _scontata(tariffa) {
      return typeof tariffa.prezzo_base !== "undefined" && tariffa.prezzo_base !== tariffa.prezzo;
    }
  }
};
</script>

<style lang="scss">
.home {
  // ____________________________ CONTENT ________________________________
  .content-grazie {
    color: #2a364d;
    background-color: white;
    margin: 20px 7px;
    position: relative;
    z-index: 1;
    // padding: 0 10% 2em;
    .title {
      padding: 0.5em 8.8%;
      font-weight: bold;
      margin: 0 0 1em;
      background-color: #c3c9df;
    }
    .wrapper {
      padding: 2em 10% 4em;
      .subtitle {
        font-size: 0.9em;
        display: block;
      }
      .text {
        color: #585858;
        font-size: 0.8em;
      }
      .cyb-input {
        margin: 5px 0;
        border: 1px solid #585858;
        input,
        textarea,
        select {
          background-color: transparent;
          padding: 4px 10px;
          outline: none !important;
          border: none;
          width: 100%;
          &::placeholder {
            opacity: 0.6;
          }
          &[disabled] {
            color: #8d8d8d !important;
            opacity: 1 !important;
            border-color: #8d8d8d !important;
          }
        }
        &.disabled {
          background-color: #e9e9e9 !important;
        }
        textarea {
          margin-bottom: -7px;
        }
        &.cyb-select {
          padding-right: 4px;
          select {
            padding: 6px;
            &:invalid {
              color: #a5a5a5;
            }
            option {
              color: #2a364d;
            }
          }
        }
      }
    }
    .cyb-errore {
      margin-left: 2px;
      color: #e6173a;
      font-size: 0.9em;
      font-style: italic;
    }
  }
}
</style>
