<template>
  <div class="home" :style="{ '--base-dark': $theme.basedark, '--base-light': $theme.baselight, '--base-color': $theme.basecolor }">
    <cyb-modal class="modal_riepilogo" :visible="confirm" :modaldata="modaldata" @modalClose="modalClose">
      <div class="row btn-footer footer-riepilogo">
        <div class="col-12 btn-riepilogo btn-avanti cybtn shadow" style="min-width:50px !important;max-width:50px !important;" :style="{ backgroundColor: $theme.basecolor }" @click="confermacancella()">
          {{ labels.onlysi }}
        </div>
        <div class="col-12 btn-riepilogo btn-avanti cybtn shadow" style="min-width:50px !important;max-width:50px !important;" :style="{ backgroundColor: $theme.basecolor }" @click="modalClose">{{ labels.onlyno }}</div>
      </div>
    </cyb-modal>
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="content-list col">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content-riepilogo" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.riepilogoPreno }}</div>
          <div class="wrapper">
            <div class="row">
              <div class="col col-md-8 col-xl-6">
                <div class="row preno-riepilogo">
                  <div class="col-6 col-md-5 preno-label">{{ labels.Preno }}:</div>
                  <div class="col-6 col-md-7 preno-value">{{ preno.numero }}</div>
                  <div class="col-6 col-md-5 preno-label">{{ labels.ANomeDi }}:</div>
                  <div class="col-6 col-md-7 preno-value">{{ preno.intestatario }}</div>
                  <div class="col-6 col-md-5 preno-label">{{ labels.DataArr }}:</div>
                  <div class="col-6 col-md-7 preno-value">{{ preno.dadata }}</div>
                  <div class="col-6 col-md-5 preno-label">{{ labels.DataPart }}:</div>
                  <div class="col-6 col-md-7 preno-value">{{ preno.adata }}</div>
                  <div class="col-6 col-md-5 preno-label">{{ labels.Tot }}:</div>
                  <div class="col-6 col-md-7 preno-value"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />{{ preno.totale }}</div>
                </div>
                <div class="row preno-righe">
                  <div class="col-12 col-xl-10">
                    <div class="row">
                      <div v-for="(row, idx) in preno.righe" :key="idx" class="col-12 preno-riga">
                        <div class="preno-border">
                          <div class="preno-riga-data">
                            <div class="preno-data-label">
                              <div class="preno-data-title">{{ labels.Room }} {{ idx + 1 }}: {{ row.camera }}</div>
                              <div class="preno-data-subtitle">{{ row.tariffa }}{{ _trattamento(row.trattamento) }}</div>
                            </div>
                            <div class="preno-data-total">
                              <div><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />{{ row.totale }}</div>
                            </div>
                          </div>
                          <div class="row preno-servizi">
                            <div v-for="(srv, cdx) in row.servizi" :key="cdx" class="col-12 preno-serv">
                              <div class="preno-serv-data">
                                <div class="preno-data-label"><fa :class="'fa plus'" :icon="['fas', 'plus']" />{{ srv.servizio }}</div>
                                <div class="preno-data-total">
                                  <div><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />{{ srv.totale }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 preno-totale">
                        <div class="preno-tot-label">{{ labels.Tot }}</div>
                        <div class="preno-tot-total"><fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />{{ preno.totale }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row btn-footer footer-riepilogo">
          <div v-if="enabled_checkin" class="col-12 btn-riepilogo btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="webCheckin()">
            {{ labels.btnWebCheckin }}
            <fa :class="'fa arrow ' + (checkin ? 'up' : 'down')" :icon="['fas', 'chevron-down']" />
          </div>
          <div v-if="from_be && enabled_annulla" class="col-12 btn-riepilogo btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="cancella()">
            {{ labels.btnCestinoPreno }}
          </div>
        </div>
        <!-- ---------------------------------- W E B  C H E C K - I N ---------------------------------- -->
        <div v-show="checkin" :class="'web-check-in checkin-' + (checkin ? 'down' : 'up')">
          <div v-for="(row, idx) in preno.righe" :key="idx" class="content-riepilogo" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
            <!-- _______________ camera _______________ -->
            <div class="row title">{{ labels.Room }} {{ idx + 1 }}: {{ row.camera }}</div>
            <div class="wrapper cyb-form" style="padding-bottom: 2em">
              <div class="camera">
                <div v-for="(per, idy) in row.persone" :key="idy" class="persona">
                  <!-- _______________ persona _______________ -->
                  <div class="persona_label">{{ labels.alloggiato }} {{ idy + 1 }}</div>
                  <div class="row">
                    <!-- alloggiato -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].alloggiato" :style="content[idx][idy].alloggiato ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.tipoAllog }}</option>
                          <option v-for="opzione in alloggiato_options" :key="opzione.key" :value="opzione.key">{{ opzione["label_" + $store.lang] ? opzione["label_" + $store.lang] : opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].alloggiato" class="cyb-errore">{{ labels.NotTipoAllog }}</div>
                    </div>
                    <!-- nome -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input">
                        <input v-model="content[idx][idy].nome" :placeholder="labels.Nome" />
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].nome" class="cyb-errore">{{ labels.NotNome }}</div>
                    </div>
                    <!-- cognome -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input">
                        <input v-model="content[idx][idy].cognome" :placeholder="labels.Cognome" />
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].cognome" class="cyb-errore">{{ labels.NotCognome }}</div>
                    </div>
                    <!-- sesso -->
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="cyb-radio riepilogo-radio">
                        <label>
                          <input v-model="content[idx][idy].sesso" type="radio" value="m" :style="{ color: $theme.basecolor }" />
                          <span for="m">{{ labels.Uomo }}</span>
                        </label>
                        <label>
                          <input v-model="content[idx][idy].sesso" type="radio" value="f" :style="{ color: $theme.basecolor }" />
                          <span for="f">{{ labels.Donna }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- cittadinanza -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].cittadinanza" :style="content[idx][idy].cittadinanza ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.Cittadinanza }}</option>
                          <option v-for="opzione in nazione_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].cittadinanza" class="cyb-errore">{{ labels.NotCittadinanza }}</div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-2">
                      <!-- data nascita -->
                      <div class="cyb-input">
                        <table width="100%" style="width: 100%">
                          <tr>
                            <td width="100%" style="width: 100%">
                              <input
                                v-model="content[idx][idy].data_nascita"
                                style="text-align: center"
                                type="text"
                                onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                :placeholder="labels.datanascita"
                              />
                            </td>
                            <td width="20px" style="width: 20px">
                              <cyb-date-picker
                                v-model="content[idx][idy].data_nascita_temp"
                                :lang="$store.lang"
                                :placeholder="labels.datanascita"
                                @input="
                                  (val) => {
                                    input_date(val, idx, idy);
                                  }
                                "
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div v-if="(errors[idx] && errors[idx][idy] && errors[idx][idy].data_nascita) || (errors[idx] && errors[idx][idy] && errors[idx][idy].data_invalid)" class="cyb-errore">
                        {{ errors[idx] && errors[idx][idy] && errors[idx][idy].data_invalid ? labels.NotDataValid : labels.NotDatanascita }}
                      </div>
                    </div>
                    <!-- nazione nascita -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].nazione_nascita" :style="content[idx][idy].nazione_nascita ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.nazionenascita }}</option>
                          <option v-for="opzione in nazione_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].nazione_nascita" class="cyb-errore">{{ labels.NotNazioneNascita }}</div>
                    </div>
                    <!-- provincia nascita -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].prov_nascita" :style="content[idx][idy].prov_nascita ? '' : 'color: #a9a9a9'" @change="_reloadCombo('comune_nascita', idx, idy, content[idx][idy].prov_nascita)">
                          <option selected disabled hidden value="">{{ labels.provnascita }}</option>
                          <option v-for="opzione in prov_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].prov_nascita" class="cyb-errore">{{ labels.NotProvNascita }}</div>
                    </div>
                    <!-- città nascita -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].comune_nascita" :style="content[idx][idy].comune_nascita ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.comunenascita }}</option>
                          <option v-for="opzione in _comuni('comune_nascita', idx, idy)" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].comune_nascita" class="cyb-errore">{{ labels.NotComuneNascita }}</div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- nazione residenza -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].nazione_residenza" :style="content[idx][idy].nazione_residenza ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.nazioneresid }}</option>
                          <option v-for="opzione in nazione_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].nazione_residenza" class="cyb-errore">{{ labels.NotNazione }}</div>
                    </div>
                    <!-- provincia residenza -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].prov_residenza" :style="content[idx][idy].prov_residenza ? '' : 'color: #a9a9a9'" @change="_reloadCombo('comune_residenza', idx, idy, content[idx][idy].prov_residenza)">
                          <option selected disabled hidden value="">{{ labels.provresid }}</option>
                          <option v-for="opzione in prov_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].prov_residenza" class="cyb-errore">{{ labels.NotProv }}</div>
                    </div>
                    <!-- città residenza -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].comune_residenza" :style="content[idx][idy].comune_residenza ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.comuneresid }}</option>
                          <option v-for="opzione in _comuni('comune_residenza', idx, idy)" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].comune_residenza" class="cyb-errore">{{ labels.NotCom }}</div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- tipo documento -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].tipodoc" :style="content[idx][idy].tipodoc ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.TipoDoc }}</option>
                          <option v-for="opzione in tipodoc_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].tipodoc" class="cyb-errore">{{ labels.NotTipoDoc }}</div>
                    </div>
                    <!-- numero documento -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input">
                        <input v-model="content[idx][idy].nrodoc" :placeholder="labels.NumDoc" />
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].nrodoc" class="cyb-errore">{{ labels.NotNumDoc }}</div>
                    </div>
                    <!-- nazione rilascio -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].nazione_rilascio" :style="content[idx][idy].nazione_rilascio ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.nazioneril }}</option>
                          <option v-for="opzione in nazione_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].nazione_rilascio" class="cyb-errore">{{ labels.NotNazioneRil }}</div>
                    </div>
                    <!-- città rilascio -->
                    <div class="col-12 col-md-6 col-xl-2">
                      <div class="cyb-input cyb-select">
                        <select v-model="content[idx][idy].comune_rilascio" :style="content[idx][idy].comune_rilascio ? '' : 'color: #a9a9a9'">
                          <option selected disabled hidden value="">{{ labels.comuneril }}</option>
                          <option v-for="opzione in comune_options" :key="opzione.key" :value="opzione.key">{{ opzione.label }}</option>
                        </select>
                      </div>
                      <div v-if="errors[idx] && errors[idx][idy] && errors[idx][idy].comune_rilascio" class="cyb-errore">{{ labels.NotComuneRil }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row btn-footer footer-riepilogo">
            <div class="col-12 btn-riepilogo btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="checkinSend()">
              {{ labels.btnCheckin }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
  </div>
</template>

<script>
import CybFooter from "../components/cybfooter.vue";
import CybDatePicker from "../components/cybdatepicker.vue";
import formService from "../services/form";
import apiService from "../services/api.js";
import baseMixin from "../mixins/base";
import CybModal from "../components/cybmodal.vue";

export default {
  components: {
    CybFooter,
    CybDatePicker,
    CybModal
  },
  mixins: [baseMixin],
  data() {
    return {
      preno: {
        id: "",
        numero: "",
        intestatario: "",
        dadata: "",
        adata: "",
        totale: "",
        righe: []
      },
      confirm: false,
      modaldata: {},
      content: [],
      errors: [],
      alloggiato_options: [],
      tipodoc_options: [],
      nazione_options: [],
      prov_options: [],
      comune_options: [],
      opt_comuni: [],
      token: "",
      checkin: false,
      from_be: true,
      // today: false,
      enabled_checkin: false,
      enabled_annulla: false,
      labels: { privacy: {} }
    };
  },
  mounted() {
    // chiamata backend per recuperare roba
    this.token = this.$route.query.token;
    if (this.token) {
      apiService
        .get("BE_riepilogo/" + this.token)
        .then((resp) => {
          if (resp) {
            this.$store.err = [];
            this.$store.db = resp.db;
            this.$store.idhotel = resp.idhotel;
            this.$store.lang = resp.lang;
            formService.init(this);
            this.loadHotel(resp);
            this.loadContent(resp);
          }
        })
        .catch((err) => {
          if (err.msg && err.msg.length > 0) {
            for (let i = 0; i < err.msg.length; i++) {
              this.$store.err.push(err.msg[i]);
            }
            this._error();
          } else {
            this._error("ERR_GENERIC");
          }
        });
      // paramentri mancanti
    } else {
      formService.init(this, false);
      this._error("ERR_PRENO");
    }
  },
  methods: {
    // ::::::::::::::::::::::::::::::::: GESTIONE DATI :::::::::::::::::::::::::::::::::
    modalClose() {
      this.confirm = false;
      setTimeout(() => {
        this.modaldata = {};
      }, 300);
    },
    input_date(val, idx, idy) {
      this.content[idx][idy].data_nascita = val;
    },
    loadContent(data) {
      this.$store.lastpage = "riepilogo";

      this.from_be = data.from_be;
      this.enabled_checkin = data.btncheckin;
      this.enabled_annulla = data.btnannulla;
      // this.today = data.today;

      // oggetto persona
      const persona = {
        idriga: "",
        alloggiato: "",
        nome: "",
        cognome: "",
        sesso: "",
        cittadinanza: "",
        data_nascita: "",
        data_invalid: "", // zozzeria
        nazione_nascita: "",
        prov_nascita: "",
        comune_nascita: "",
        nazione_residenza: "",
        prov_residenza: "",
        comune_residenza: "",
        tipodoc: "",
        nrodoc: "",
        nazione_rilascio: "",
        comune_rilascio: ""
      };

      var errori = {};
      for (var field in persona) {
        errori[field] = false;
      }

      // carico l'oggetto content per i v-model
      this.$set(this, "content", []);
      for (var idx in data.righe) {
        this.$set(this.content, idx, []);
        this.$set(this.errors, idx, []);
        for (var idy in data.righe[idx].ospiti) {
          this.$set(this.content[idx], idy, {
            ...{ ...persona, ...{ sesso: "m", idgroup: data.righe[idx].idgroup } },
            ...data.righe[idx].ospiti[idy]
          });
          this.$set(this.errors[idx], idy, { ...errori });
        }
      }

      // combobox
      this.nazione_options = data.nazioni;
      this.prov_options = data.province;
      this.comune_options = data.comuni;
      this.alloggiato_options = data.alloggiato;
      this.tipodoc_options = data.tipodoc;

      // carico i dati della prenotazione
      this.preno = { ...data.preno, righe: data.righe };
    },
    loadHotel(data) {
      // dati principali hotel
      for (var item in data.hotel) {
        if (Object.prototype.hasOwnProperty.call(data.hotel, item)) {
          this.$hotel[item] = data.hotel[item];
        }
      }
      // colori e sfondo
      this.$theme.basecolor = data.colore;
      this.$theme.eurosign = data.eurosign;
      this.$theme.basedark = formService.brightness(this.$theme.basecolor, -50);
      this.$theme.baselight = formService.brightness(this.$theme.basecolor, 50);
      if (data.sfondo) {
        this.$theme.background = data.sfondo;
      }
    },
    confermacancella() {
      apiService.delete("BE_prenotazione/" + this.token).then(() => {
        this.reset();
      });
      this.modalClose();
    },
    cancella() {
      this.modaldata = {
        titolo: this.labels.attenzione,
        descrizione: this.labels.confermadel
      };
      this.confirm = true;
    },
    webCheckin() {
      this.checkin = !this.checkin;
    },
    checkinSend() {
      apiService
        .post("BE_prenotazione/webcheckin/" + this.token, { camere: this.content })
        .then(() => {
          this.reset();
          this.$store.form = this.form;
          this.$router.push("/graziecheckin");
        })
        .catch((err) => {
          this.errors = { ...err.errors };
        });
    },
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
    _reloadCombo(combo, camera, persona, param) {
      this.content[camera][persona][combo] = "";
      apiService.get("BE_riepilogo/comuni/" + param).then((resp) => {
        if (typeof this.opt_comuni[camera] === "undefined") {
          this.$set(this.opt_comuni, camera, []);
        }
        if (typeof this.opt_comuni[camera][persona] === "undefined") {
          this.$set(this.opt_comuni[camera], persona, []);
        }
        this.$set(this.opt_comuni[camera][persona], combo, resp.opzioni);
      });
    },
    _comuni(campo, camera, persona) {
      if (this.opt_comuni && this.opt_comuni[camera] && this.opt_comuni[camera][persona] && this.opt_comuni[camera][persona][campo]) {
        return this.opt_comuni[camera][persona][campo];
      }
      return this.comune_options;
    },
    _trattamento(label) {
      if (label) {
        return " [" + label + "]";
      }
    }
  }
};
</script>

<style lang="scss">
.modal_riepilogo {
  .modaltor__panel {
    width:80% !important;
    max-width:400px !important;
  }
}
.cyb-input {
  margin: 5px 0;
  border: 1px solid #585858;
  input,
  textarea,
  select {
    background-color: transparent;
    // padding: 4px 1px;
  }
}
.home {
  // ____________________________ CONTENT ________________________________
  .content-riepilogo {
    color: #2a364d;
    background-color: white;
    margin: 20px 7px;
    position: relative;
    z-index: 1;
    // padding: 0 10% 2em;
    .title {
      text-transform: uppercase;
      padding: 0.5em 8.8%;
      font-weight: bold;
      margin: 0 0 1em;
      background-color: #c3c9df;
    }
    .wrapper {
      padding: 0 10% 4em;
      .euro {
        margin-right: 3px;
        padding: 1px;
      }
      .preno-label {
        font-weight: bold;
      }
      .preno-righe {
        margin-top: 30px;
        .preno-riga .preno-border {
          border-bottom: 1px solid #d4d4d4;
          padding-bottom: 8px;
        }
        .preno-riga:nth-last-of-type(2) .preno-border {
          border-bottom: 1px solid black;
        }
        .preno-riga-data {
          .preno-data-label {
            .preno-data-title {
              font-weight: bold;
              height: 1.3em;
            }
            .preno-data-subtitle {
              opacity: 0.5;
              font-size: 0.8em;
              font-weight: lighter;
            }
          }
        }
        .preno-serv-data {
          font-size: 0.9em;
        }
        .preno-riga-data,
        .preno-serv-data {
          display: flex;
          justify-content: space-between;
          .plus {
            margin: 0 3px;
            padding: 1px;
          }
          .preno-data-total {
            color: #4dbf8f;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .preno-totale {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        .preno-tot-label {
          font-weight: bold;
        }
        .preno-tot-total {
          color: #4dbf8f;
          font-weight: bold;
        }
      }
    }
  }
  .web-check-in {
    overflow: hidden;
    padding-bottom: 85px;
    .camera {
      padding-bottom: 1em;
      .camera-titolo {
        font-weight: bold;
      }
      .riepilogo-radio {
        input {
          margin-right: 4px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .content-riepilogo:first-child {
      margin-top: 0;
    }
    .persona {
      &:not(:first-child) {
        padding-top: 5px;
      }
      .persona_label {
        border-bottom: 1px solid #00000088;
        opacity: 0.5;
        font-size: 0.8em;
        font-weight: lighter;
        padding-bottom: 5px;
      }
    }
  }
  .btn-footer.footer-riepilogo {
    margin: 0;
    .btn-riepilogo {
      margin-bottom: 15px;
      max-width: 350px;
      .arrow {
        transition: transform 0.2s linear;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }
}
@media (max-width: 830px) {
  .home {
    .btn-footer.footer-riepilogo {
      padding-left: 14px;
      .btn-riepilogo {
        max-width: 100% !important;
      }
    }
  }
}
</style>
