import Vue from "vue";
import VueRouter from "vue-router";
import Camere from "../views/camere.vue";
import Errore from "../views/errore.vue";
import Form from "../views/form.vue";
import Grazie from "../views/grazie.vue";
import GrazieCheckin from "../views/graziecheckin.vue";
import Riepilogo from "../views/riepilogo.vue";
import Servizi from "../views/servizi.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "camere",
    component: Camere
  },
  {
    path: "/servizi",
    name: "Servizi",
    component: Servizi
  },
  {
    path: "/form",
    name: "Form",
    component: Form
  },
  {
    path: "/errore",
    name: "Errore",
    component: Errore
  },
  {
    path: "/grazie",
    name: "Grazie",
    component: Grazie
  },
  {
    path: "/riepilogo",
    name: "Riepilogo",
    component: Riepilogo
  },
  {
    path: "/graziecheckin",
    name: "Grazie Checkin",
    component: GrazieCheckin
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
