<template>
  <div class="cyb-input-base">
    <!-- ----------- checkbox ----------- -->
    <div v-if="type === 'check'" class="cyb-check">
      <div>
        <input :checked="value" type="checkbox" v-bind="$attrs" :style="{ accentColor: $theme.basecolor }" @change="(evt) => $emit('input', evt.target.checked)" v-on="filteredListeners" />
      </div>
      <div class="cyb-label" @click="$emit('input', !value)">
        {{ _label }}
      </div>
    </div>
    <!-- ------------- radio ------------ -->
    <div v-else-if="type === 'radio'" class="cyb-radio">
      <div>
        <input :checked="value" :value="val" type="radio" v-bind="$attrs" :style="{ accentColor: $theme.basecolor }" @change="(evt) => $emit('input', val)" v-on="filteredListeners" />
      </div>
      <div :for="val" class="cyb-label">
        {{ _label }}
      </div>
    </div>
    <!-- ----------- textarea ----------- -->
    <div v-else-if="type === 'textarea'" class="cyb-input">
      <textarea :value="value" :placeholder="_label" v-bind="$attrs" @input="(evt) => $emit('input', evt.target.value)" v-on="filteredListeners" />
    </div>
    <!-- ------------ select ------------ -->
    <div v-else-if="type === 'select'" class="cyb-input cyb-select">
      <select :value="value" v-bind="$attrs" @change="(evt) => $emit('input', evt.target.value)" v-on="filteredListeners">
        <option selected disabled hidden value="">
          {{ _label }}
        </option>
        <option v-for="opt in options" :key="opt[optKey]" :value="opt[optKey]" :disabled="opt.disabled">
          {{ opt[optLabel] }}
        </option>
      </select>
    </div>
    <!-- -------- input standard -------- -->
    <div v-else class="cyb-input">
      <input :value="value" :placeholder="_label" v-bind="$attrs" @input="(evt) => $emit('input', evt.target.value)" v-on="filteredListeners" />
    </div>
    <!-- ============ errore ============ -->
    <div v-if="errors.length > 0">
      <div v-for="(item, idx) in errors" :key="idx">
        <div v-if="errors[idx]" class="cyb-errore">
          {{ msg[idx] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CybInput",
  props: {
    value: { type: null, default: "" },
    val: { type: null, default: "" },
    type: { type: String, default: "text" },
    label: { type: String, default: "" },
    mandatory: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    optKey: { type: String, default: "key" },
    optLabel: { type: String, default: "label" },
    errors: { type: Array, default: () => [] },
    msg: { type: Array, default: () => [] }
  },
  computed: {
    _label() {
      return this.label + (this.mandatory ? " (*)" : "");
    },
    filteredListeners() {
      var listen = {};
      for (var i in this.$listeners) {
        if (i !== "input" && i !== "change") {
          listen[i] = this.$listeners[i];
        }
      }
      return listen;
    }
  }
};
</script>

<style lang="scss">
.cyb-input {
  margin: 5px 0;
  border: 1px solid #585858;
  input,
  textarea,
  select {
    background-color: transparent;
    padding: 4px 10px;
    outline: none !important;
    border: none;
    width: 100%;
    &::placeholder {
      opacity: 0.6;
      text-align: left;
    }
    &:focus {
      border-bottom: 2px solid var(--base-color);
      margin-bottom: -2px;
    }
    &[disabled] {
      background-color: #f0f0f5 !important;
    }
  }
  textarea {
    margin-bottom: -7px !important;
    &:focus {
      margin-bottom: -9px !important;
    }
  }

  &.cyb-select {
    padding-right: 4px;
    select {
      padding: 6px;
      &:invalid {
        color: #a5a5a5 !important;
      }
      option {
        color: #2a364d;
      }
    }
  }
}
.cyb-check,
.cyb-radio {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 32px;
  margin: 10px 0 0;
  input {
    width: 1em;
    height: 1em;
    &:focus {
      outline-color: var(--base-color);
      outline-offset: 0;
    }
  }
  .cyb-label {
    padding-bottom: 2px !important;
  }
  input,
  div {
    cursor: pointer !important;
  }
  div:first-child {
    margin-right: 8px;
  }
}
</style>
