export default {
  install: function(Vue, options) {
    Vue.prototype.$store = Vue.observable({
      db: "",
      idhotel: "",
      lang: "",
      idlang: "",
      descsottototali: "",
      err: [],
      form: null,
      lastpage: ""
    });
    Vue.prototype.$theme = Vue.observable({
      eurosign: "euro-sign",
      basecolor: "#e6173a",
      basedark: "",
      baselight: "",
      // classe per gestire il colore del datepicker
      colorclass: "",
      background: "sfondo.jpg"
    });
    Vue.prototype.$hotel = Vue.observable({
      ragsoc: "",
      riga1: "",
      riga2: "",
      riga3: "",
      riga4: "",
      indirizzo: "",
      citta: "",
      prov: "",
      email: "",
      tel1: "",
      tel2: "",
      logo: ""
    });
  }
};
