<template>
  <div
    class="home"
    :style="{
      '--base-dark': $theme.basedark,
      '--base-light': $theme.baselight,
      '--base-color': $theme.basecolor
    }"
  >
    <div v-show="clickconferma" id="layerwait" class="layerwaitglob">
      <div class="layerwaitglob-div">
        <fa icon="sync-alt" class="fa-spin" style="height: 150px; position: fixed; left: 40%; top: 50%"></fa>
      </div>
    </div>
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" style="min-width: 350px">
        <!-- -------------------------------------------- S I D E P A N E L -------------------------------------------- -->
        <div class="side-bar">
          <div class="sidepanel shadow disabled">
            <div class="row">
              <!-- ---------------- DATEPICKER ---------------- -->
              <div class="col-picker col-10 col-lg-11 col-xl-10">
                <div class="side picker" style="margin-top: 0">
                  <div class="fake-picker">
                    <div class="fake-icon">
                      <fa class="fa calendar" :style="{ color: $theme.basecolor }" :icon="['far', 'calendar-alt']" />
                    </div>
                    <div class="fake-arrivo">
                      <div class="label">
                        {{ labels.datepicker ? labels.datepicker["check-in-label"] : "" }}
                      </div>
                      <div class="date">{{ dataArrivo }}</div>
                    </div>
                    <div class="fake-icon">
                      <fa class="fa arrow" :style="{ color: $theme.basecolor }" :icon="['fas', 'chevron-right']" />
                    </div>
                    <div class="fake-partenza">
                      <div class="label">
                        {{ labels.datepicker ? labels.datepicker["check-out-label"] : "" }}
                      </div>
                      <div class="date">{{ dataPartenza }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- NOTTI -------------------- -->
              <div class="col-notti col-2 col-lg-1 col-xl-2">
                <div class="side notti">
                  <fa class="fa moon" :icon="['far', 'moon']" :title="labels.notti" /><span class="label">{{ form.periodo.notti ? form.periodo.notti : 1 }}</span>
                </div>
              </div>
            </div>
            <!-- -------------------- TOTALI CAMERE -------------------- -->
            <div class="side totali ospiti">
              <div v-for="camera in form.ospiti" :key="camera.key" class="room">
                <div class="room-header">
                  <!-- ---- label ---- -->
                  <div class="room-preno">
                    <div class="room-title">
                      <div>{{ camera_label(camera) }}</div>
                      <div class="room-subtitle">
                        {{ tariffa_label(camera) }}
                      </div>
                    </div>
                    <div class="room-total">
                      <div v-if="_scontata(camera.tariffa)" class="total-price discount-price">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ servizio_prezzo(camera, false) }}
                      </div>
                      <div class="total-price">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ servizio_prezzo(camera) }}
                      </div>
                    </div>
                  </div>
                  <div v-for="(srv, idx) in camera.servizi" :key="idx" class="room-servizi">
                    <div class="room-srv"><fa :class="'fa supp'" :icon="['fas', 'plus']" />{{ srv.titolo }}</div>
                    <div class="room-total">
                      <div class="total-price">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ srv.prezzo }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------- TOTALE -------------------- -->
              <div class="rooms-totale-wrapper">
                <div class="rooms-totlabel">
                  {{ labels.Tot }}
                </div>
                <div class="room-total">
                  <div class="total-price">
                    <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                    {{ prezzo_totale() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------- CODICE COUPON -------------------- -->
          <div v-show="true" class="side-doppio side-btn coupon-wrapper">
            <div class="btn-doppio sidepanel shadow">
              <div class="row">
                <div class="col-12 col-md-12">
                  <cyb-input v-model="coupon.code" :label="labels.Coupon" :disabled="coupON" />
                  <div v-show="coupon.status !== ''" :class="'coupon-msg' + (coupOFF ? ' cyb-errore' : '')"><fa v-if="coupON" class="fa check" :icon="['fas', 'check']" />{{ coupOFF ? labels.errCoupon : labels.okCoupon }}</div>
                </div>
              </div>
            </div>
            <div v-show="!coupON" class="btn-doppio bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="attivaCoupon()">
              {{ labels.Attiva }}
            </div>
          </div>
          <!-- -------------------- DESCRIZIONE PARAMETRIZZABILE DEL CLIENTE -------------------- -->
          <div v-show="this.$store.descsottototali != ''" class="sidepanel shadow">
            <div class="row">
              <div class="col-12 col-sm-12">
                <!-- ---------------- DESCRIZIONE ---------------- -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="side" style="margin-top: 0" v-html="this.$store.descsottototali"></div>
              </div>
            </div>
          </div>
          <!-- -------------------- BOTTONE AVANTI -------------------- -->
          <div class="side-doppio side-btn">
            <div class="btn-doppio bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="goBack()">
              <fa class="fa search indietro" :icon="['fas', 'chevron-left']" />
              {{ labels.btnIndietro }}
            </div>
            <div class="btn-doppio bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" :disabled="clickconferma" @click="conferma()">
              {{ labels.btnConferma }}
              <fa class="fa search" :icon="['fas', 'chevron-right']" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-list col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content-form" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.InsDati }}</div>
          <div class="wrapper cyb-form">
            <div class="row">
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.nome" :label="labels.Nome" :mandatory="mandatory.nome" :errors="[errors.nome]" :msg="[labels.NotNome]" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.cognome" :label="labels.Cognome" :mandatory="mandatory.cognome" :errors="[errors.cognome]" :msg="[labels.NotCognome]" />
              </div>
              <div v-show="this.$store.lang == 'ITA' || this.$store.lang == null" class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <cyb-input v-model="content.codfisc" :label="labels.CodFisc" :mandatory="mandatory.codfisc" :errors="[errors.codfisc]" :msg="[labels.NotCodFisc]" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.tel" :label="labels.Tel" :mandatory="mandatory.tel" :errors="[errors.tel]" :msg="[labels.NotTel]" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.indirizzo" :label="labels.Indirizzo" :mandatory="mandatory.indirizzo" :errors="[errors.indirizzo]" :msg="[labels.NotIndirizzo]" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.email" :label="labels.Email2" :mandatory="mandatory.email" :errors="[errors.email, errors.emailnonval, errors.emaildiverse]" :msg="[labels.errEmail, labels.errEmailnonVal, labels.errEmailDiverse]" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.conferma" :label="labels.Emailconf2" :mandatory="mandatory.email" :errors="[errors.conferma, errors.confermanonval]" :msg="[labels.errEmailconf, labels.errEmailconfnonVal]" />
              </div>
              <div class="col-12">
                <cyb-input v-model="content.note" :label="labels.Note" type="textarea" :mandatory="mandatory.note" :errors="[errors.note]" :msg="[labels.NotNote]" />
              </div>
            </div>
            <div class="row para" style="margin-bottom: 3em">
              <div class="col-12 subtitle">{{ labels.Privacy }}:</div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="col-12 text" v-html="privacy"></div>
            </div>
            <div class="row">
              <div class="col-12">
                <!-- ------------ SELECT TIPO PAGAMENTO ------------ -->
                <cyb-input
                  v-model="content.tipopag"
                  required
                  :label="labels.TipoPagN"
                  :options="tipopag_options"
                  type="select"
                  :errors="[errors.tipopag, isCarta && errors.cctoken, (isNexiCarta || isNexiSemplice) && errors.nexi]"
                  :msg="[labels.NotTipoPag, labels.CardError, nexiError]"
                  @input="tipoPagSelected()"
                />
                <div class="text">{{ labels.Garanzia }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------- FORM CCS -------------------- -->
        <div v-show="isCarta" :class="'content-form ccs-form' + (isCarta ? ' ccs-visible' : '')" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.InsDati }}</div>
          <div class="wrapper cyb-form">
            <!-- layer wait sperimentale -->
            <div v-if="isCarta && load" class="layer-wait">
              <div class="layer-wait-icon">
                <fa icon="sync-alt" class="fa-spin"></fa>
              </div>
            </div>
            <!-- ccs status completed -->
            <div v-if="isCarta && content.cctoken" class="layer-done">
              <div class="layer-done-icon"><fa icon="check"></fa></div>
            </div>
            <!-- iframe ccs -->
            <div v-show="isCarta && ccs.url && content.cctoken == ''" class="row">
              <iframe ref="ccsframe" class="cyb-frame" :src="ccs.url" @load="onload" />
            </div>
          </div>
        </div>
        <!-- ------------------- FORM NEXI -------------------- -->
        <div v-if="isNexiSemplice || isNexiCarta" v-show="false">
          <form ref="form_nexi" method="POST" :action="nexi.requesturl">
            <div v-for="(item, idx) in nexi.data" :key="idx">
              <b style="background-color: lime; display: inline-block; min-width: 150px">&nbsp;{{ idx }}&nbsp;</b>
              <input :value="item" :name="idx" />
            </div>
          </form>
        </div>
        <!-- ------------------ FATTURAZIONE ------------------ -->
        <div v-if="isNexiSemplice" class="content-form" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="row title">{{ labels.FattDati }}</div>
          <div class="wrapper cyb-form">
            <div class="row">
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.stesso" :label="labels.FattStesso" type="check" />
              </div>
              <div v-show="!content.fatturazione.stesso" class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.tipo" :label="labels.TipoDoc" :options="tipofatt_options" type="select" @input="tipoFattSelected()" />
              </div>
            </div>
            <div v-show="!content.fatturazione.stesso" class="row">
              <!-- azienda / fattura -->
              <div v-if="isFattura" class="col-12">
                <cyb-input v-model="content.fatturazione.ragsoc" :label="labels.RagSoc" mandatory :errors="[errors.fatt_ragsoc]" :msg="[labels.NotRagsoc]" />
              </div>
              <!-- persona / ricevuta -->
              <div v-else class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <cyb-input v-model="content.fatturazione.nome" :label="labels.Nome" mandatory :errors="[errors.fatt_nome]" :msg="[labels.NotNome]" />
                  </div>
                  <div class="col-12 col-md-6">
                    <cyb-input v-model="content.fatturazione.cognome" :label="labels.Cognome" mandatory :errors="[errors.fatt_cognome]" :msg="[labels.NotCognome]" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.tel" :label="labels.Tel" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.mail" :label="labels.Email2" />
              </div>
              <div class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.codfisc" :label="labels.CodFisc2" />
              </div>
              <div v-if="isFattura" class="col-12 col-md-6">
                <cyb-input v-model="content.fatturazione.piva" :label="labels.Piva2" />
              </div>
            </div>
            <div v-show="!content.fatturazione.stesso" class="row para" :style="{ paddingBottom: '0' }">
              <div class="col-12 subtitle">{{ labels.Indirizzo + ":" }}</div>
            </div>
            <div v-show="!content.fatturazione.stesso" class="row">
              <div class="col-12 col-md-8">
                <cyb-input v-model="content.fatturazione.indirizzo" :label="labels.Indirizzo" type="textarea" />
              </div>
              <div class="col-12 col-md-4">
                <cyb-input v-model="content.fatturazione.nazione" :label="labels.Nazione" :options="nazione_options" opt-key="id" opt-label="descr" required type="select" @input="nazioneSelected()" />
              </div>
              <div class="col-12 col-md-8">
                <cyb-input v-model="content.fatturazione.regione" :label="labels.Regione" :options="regione_options" opt-key="id" opt-label="nome" required type="select" @input="regioneSelected()" />
              </div>
              <div class="col-12 col-md-4"></div>
              <div class="col-12 col-md-4">
                <cyb-input v-model="content.fatturazione.prov" :label="labels.Prov" :options="prov_options" opt-key="id" opt-label="nome" required type="select" @input="provSelected()" />
              </div>
              <div class="col-12 col-md-4">
                <cyb-input v-model="content.fatturazione.citta" :label="labels.Comune" :options="citta_options" opt-key="id" opt-label="nome" required type="select" @input="cittaSelected()" />
              </div>
              <div class="col-12 col-md-4">
                <cyb-input v-model="content.fatturazione.cap" :label="labels.Cap" />
              </div>
            </div>
            <div v-show="!content.fatturazione.stesso" class="row para" :style="{ paddingBottom: '0' }">
              <div class="col-12 subtitle">{{ labels.FattElett + ":" }}</div>
            </div>
            <div v-show="!content.fatturazione.stesso" class="row">
              <div class="col-12">
                <cyb-input v-model="content.fatturazione.fe_attivo" :label="labels.Attivo" type="check" @input="svuota" />
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <cyb-input v-model="content.fatturazione.fe_codsdi" :label="labels.feCodSdi" :disabled="!content.fatturazione.fe_attivo" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <cyb-input v-model="content.fatturazione.fe_pec" :label="labels.fePec" :disabled="!content.fatturazione.fe_attivo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------- POLICY --------------------- -->
        <div class="content-form" :style="{ borderRight: '4px solid ' + $theme.basecolor }">
          <div class="wrapper cyb-form">
            <div class="row para">
              <div class="col-12 subtitle">{{ labels.Policy }}:</div>
              <div class="col-12 text">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-for="(pol, idx) in policy" :key="idx" class="policy-text" :style="{ borderColor: $theme.basecolor }" v-html="pol"></div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <cyb-input v-model="content.policy" :label="labels.Si" type="radio" val="si" name="policy" mandatory :style="{ marginRight: '15px' }" />
                <cyb-input v-model="content.policy" :label="labels.No" type="radio" val="no" name="policy" :errors="[errors.policy]" :msg="[labels.Accettare]" />
              </div>
              <div class="col-12 d-flex">
                <div class="text">{{ labels.Campiobbligatori }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-footer">
          <div class="btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" :disabled="clickconferma" @click="conferma()">
            {{ labels.btnConferma }}
            <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
    <!-- -------------------------------------------- M O D A L S -------------------------------------------- -->
    <cyb-modal :visible="modal" :modaldata="modaldata" @modalClose="modalClose" />
  </div>
</template>

<script>
import CybInput from "../components/cybinput.vue";
import CybFooter from "../components/cybfooter.vue";
import CybModal from "../components/cybmodal.vue";
import formService from "../services/form";
import baseMixin from "../mixins/base";
import apiService from "../services/api.js";
import short from "short-uuid";

export default {
  components: {
    CybInput,
    CybFooter,
    CybModal
  },
  mixins: [baseMixin],
  data() {
    return {
      form: {
        periodo: {
          dataarrivo: "",
          datapartenza: "",
          notti: 0,
          couponcode: ""
        },
        ospiti: []
      },
      errors: {
        nome: false,
        cognome: false,
        codfisc: false,
        tel: false,
        indirizzo: false,
        email: false,
        emailnonval: false,
        emaildiverse: false,
        conferma: false,
        confermanonval: false,
        note: false,
        tipopag: false,
        cctoken: false,
        policy: false,
        nexi: false,
        fatt_nome: false,
        fatt_cognome: false,
        fatt_ragsoc: false
      },
      mandatory: {
        nome: false,
        cognome: false,
        codfisc: false,
        tel: false,
        indirizzo: false,
        email: false,
        note: false
      },
      content: {
        nome: "",
        cognome: "",
        codfisc: "",
        tel: "",
        indirizzo: "",
        email: "",
        conferma: "",
        note: "",
        tipopag: "",
        cctoken: "",
        policy: "no",
        fatturazione: {
          stesso: true,
          tipo: "",
          ragsoc: "",
          nome: "",
          cognome: "",
          tel: "",
          mail: "",
          codfisc: "",
          piva: "",
          indirizzo: "",
          nazione: "",
          regione: "",
          prov: "",
          citta: "",
          cap: "",
          fe_attivo: false,
          fe_codsdi: "",
          fe_pec: ""
        }
      },
      ccs: {
        token: "",
        uitoken: "",
        url: ""
      },
      nexi: {
        requesturl: "",
        data: {
          alias: "",
          codTrans: "",
          divisa: "",
          importo: 0,
          mac: "",
          mail: "",
          nome: "",
          cognome: "",
          descrizione: "",
          url: "",
          url_back: "",
          languageId: "",
          // tipo_richiesta: "",
          xpayTimeout: 300
        }
      },
      privacy: "",
      policy: [],
      tipopag_options: [],
      tipofatt_options: [],
      nazione_options: [],
      regione_options: [],
      prov_options: [],
      citta_options: [],
      labels: { privacy: {} },
      ccs_controller: "prenotazioni_ccs",
      load: null,
      modal: false,
      clickconferma: false,
      coupon: {
        code: "",
        status: ""
      }
    };
  },
  computed: {
    isNexiSemplice() {
      return this.content.tipopag === "PN";
    },
    isNexiCarta() {
      return this.content.tipopag === "CCG";
    },
    isCarta() {
      return this.content.tipopag === "CC";
    },
    isFattura() {
      return this.content.fatturazione.tipo === "FC";
    },
    isItalia() {
      return this.content.fatturazione.nazione === "100000100";
    },
    coupON() {
      return this.coupon.status === "OK";
    },
    coupOFF() {
      return this.coupon.status === "KO";
    },
    dataArrivo() {
      return this.form.periodo.dataarrivo ? this.form.periodo.dataarrivo : this.labels.datepicker !== undefined ? this.labels.datepicker["check-in"] : "";
    },
    dataPartenza() {
      return this.form.periodo.datapartenza ? this.form.periodo.datapartenza : this.labels.datepicker !== undefined ? this.labels.datepicker["check-out"] : "";
    },
    nexiError() {
      return this.labels["XpayError" + this.errors.nexi] ? this.labels["XpayError" + this.errors.nexi] : this.labels.XpayError;
    },
    modaldata() {
      return {
        titolo: this.labels.ERR,
        descrizione: this.nexiError
      };
    }
  },
  mounted() {
    // gestione rientro da nexi
    if (typeof this.$route.query.token !== "undefined" && this.$route.query.token.length === 11) {
      apiService.post("BE_form/decodenexi/" + this.$route.query.token, this.$route.query).then((resp) => {
        // this.content = resp.data.content;
        this._fill(this.content, resp.data.content);
        this._fill(this.$hotel, resp.data.hotel);
        this._fill(this.$theme, resp.data.theme);
        this._fill(this.$store, resp.data.store);
        this.$store.form = resp.data.form;
        formService.init(this);

        // gestione differente a seconda dell'esito del pagamento
        switch (this.$route.query.esito) {
          case "KO":
          case "ERRORE":
            this.errors.nexi = true;
            if (this.$route.query.codiceEsito) {
              this.errors.nexi = parseInt(this.$route.query.codiceEsito);
            }

            // modale per errore nexi
            this.modal = true;

          // eslint-disable-next-line no-fallthrough
          case "ANNULLO":
            break;
        }
      });
    } else {
      formService.init(this);
    }

    this.$root.$on("form:reload", () => {
      this.loadContent();
    });
    this.$root.$off("form:finalize");
    this.$root.$on("form:finalize", () => {
      this.beforeFinalize();
    });

    if (this.form.prenotata) {
      this.reset();
    }
    // this.loadContent();
  },
  destroyed() {
    if (!this.form.prenotata) {
      this.prezziScontati();
    }
    this.$root.$off("form:reload");
  },
  methods: {
    // ::::::::::::::::::::::::::::::::: GESTIONE DATI :::::::::::::::::::::::::::::::::
    loadContent() {
      this.$store.lastpage = "form";

      // chiamata backend per recuperare roba
      if (this.$store.db && this.$store.idhotel) {
        apiService.post("BE_form/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.$store.lang + "/" + this.$store.idlang, this.form).then((resp) => {
          if (resp) {
            this.privacy = resp.privacy;
            this.policy = resp.policy;
            this.tipopag_options = resp.tipipag;
            this.tipofatt_options = resp.tipifatt;
            this.ccs.uitoken = resp.ccs_token;

            // zozzeria
            this.content.fatturazione.tipo = "FC";

            // combobox
            this.combo("nazioni").then((data) => {
              this.nazione_options = data;
            });
            this.combo("comuni").then((data) => {
              this.citta_options = data;
            });

            // campi obbligatori
            this._fill(this.mandatory, resp.mandatory);
          }
        });

        // paramentri mancanti
      } else {
        var err = [];
        if (!this.$store.db || !this.$store.idhotel) {
          err.push("ERR_RPC");
        }
        this._error(err);
      }
      this.prezziScontati();
      // this.form.ospiti.map((el) => {});
    },
    goBack() {
      this.prezziScontati();
      this.clickconferma = false;
      this.form.couponcode = "";
      this._goNext("/servizi");
    },
    conferma() {
      if (!this.clickconferma) {
        this.clickconferma = true;
        this.$root.$emit("form:finalize");
      }
    },
    attivaCoupon() {
      apiService
        .post("BE_form/coupon/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.coupon.code, { camere: this.form.ospiti })
        .then((resp) => {
          this.prezziScontati(resp.sconti);
          this.coupon.status = "OK";
          this.form.couponcode = this.coupon.code;
          // this.form.ospiti.map((el) => {});
        })
        .catch(() => {
          this.coupon.status = "KO";
        });
    },
    // se l'array sconti è vuoto gli sconti vengono rimossi
    prezziScontati(sconti = []) {
      this.form.ospiti.map((el) => {
        if (el.tariffa && el.tariffa.prezzo) {
          if (typeof sconti[el.codice] !== "undefined" && sconti[el.codice].tiposconto) {
            el.tariffa.prezzo_base = el.tariffa.prezzo;
            var prezzo = formService._toFloat(el.tariffa.prezzo);
            var sconto = parseFloat(sconti[el.codice].valore);

            switch (sconti[el.codice].tiposconto) {
              case "MI": // il valore va semplicemente sommato
                el.tariffa.prezzo = prezzo + sconto;
                break;
              case "MP": // il valore è una percentuale da aggiungere
                el.tariffa.prezzo = prezzo + prezzo * (sconto / 100);
                break;
              case "RI": // il valore va sotratto
                el.tariffa.prezzo = prezzo - sconto;
                break;
              case "SP": // il valore è una percentuale da sottrarre
                el.tariffa.prezzo = prezzo - prezzo * (sconto / 100);
                break;
            }
            if (el.tariffa.prezzo < 0) {
              el.tariffa.prezzo = 0;
            }
            el.tariffa.prezzo = formService._toStr(el.tariffa.prezzo);
          } else if (typeof el.tariffa.prezzo_base !== "undefined") {
            el.tariffa.prezzo = el.tariffa.prezzo_base;
          }
        }
      });
    },
    beforeFinalize() {
      for (var key in this.errors) {
        this.errors[key] = false;
      }
      this.validateForm().then(() => {
        if (this.$store.db && this.$store.idhotel) {
          if (this.isNexiSemplice || this.isNexiCarta) {
            // passo i dati di (praticamente tutto il booking) per salvarli nella sessione
            var param = {
              store: {
                db: this.$store.db,
                idhotel: this.$store.idhotel,
                lang: this.$store.lang,
                idlang: this.$store.idlang,
                descsottototali: this.$store.descsottototali
              },
              theme: this.$theme,
              hotel: this.$hotel,
              form: this.form,
              content: this.content,
              totale: this.prezzo_totale()
            };
            apiService.post("BE_form/datinexi", param).then((resp) => {
              this.nexi.requesturl = resp.requesturl;
              this.nexi.data = { ...resp.datinexi };
              if (this.isNexiCarta) {
                this.nexi.data.tipo_richiesta = "VC";
              }

              setTimeout(() => {
                var rif = this.$refs.form_nexi;
                rif.submit();
              }, 500);
            });
          } else {
            this.finalize(this.content, "/grazie");
          }
        } else {
          // paramentri mancanti
          var err = [];
          if (!this.$store.db) {
            err.push("ERR_RPC");
          }
          if (!this.$store.idhotel) {
            err.push("ERR_IDHOTEL");
          }
          this._error(err);
        }
      });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        apiService.post("BE_form/checkdata", { content: this.content, mandatory: this.mandatory, errors: this.errors, lang: this.$store.lang }).then((resp) => {
          if (resp.stop) {
            this.clickconferma = false;
            this._fill(this.errors, resp.errors);
            reject();
          } else {
            resolve();
          }
        });
      });
    },
    onload() {
      if (this.load === null) {
        this.load = true;
      } else {
        if (this.load) {
          this.load = false;
        } else {
          if (this.isCarta && this.ccs.token !== "") {
            this.sessionCheck();
          }
        }
      }
    },
    sessionCheck(ttl = 100) {
      apiService.get(this.ccs_controller + "/uisession/" + this.ccs.token).then((resp) => {
        if (resp.msg) {
          // sessione UI finita
          this.content.cctoken = resp.msg;
          apiService.get(this.ccs_controller + "/uidelete/" + this.ccs.token).then(() => {
            this.ccs.token = "";
          });
        } else if (resp.active && ttl > 0) {
          // sessione UI in corso
          ttl--;
          setTimeout(() => {
            this.sessionCheck(ttl);
          }, 500);
        }
      });
    },
    // :::::::::::::::::::::::::::::::: COMBO SELECTED ::::::::::::::::::::::::::::::::
    tipoPagSelected() {
      if (this.isCarta) {
        if (this.content.cctoken === "" && this.ccs.url === "") {
          this.ccs.token = this.uuid();
          apiService.get(this.ccs_controller + "/carta_be/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.ccs.token).then((res) => {
            this.ccs.url = res.redirectUrl;
          });
        }
      }
    },
    tipoFattSelected() {},
    nazioneSelected(auto = true) {
      if (this.isItalia) {
        this.combo("regioni", "regioni", this.content.fatturazione.nazione).then((data) => {
          this.regione_options = data;
        });
      } else {
        this.regione_options = [];
      }
      if (auto) {
        this.content.fatturazione.regione = "";
      }
      this.regioneSelected(auto);
    },
    regioneSelected(auto = true) {
      if (this.isItalia) {
        this.combo("province", "province", this.content.fatturazione.regione).then((data) => {
          this.prov_options = data;
        });
      } else {
        this.prov_options = [];
      }
      if (auto) {
        this.content.fatturazione.prov = "";
      }
      this.provSelected(auto);
    },
    provSelected(auto = true) {
      this.combo("comuni", "comuni", this.content.fatturazione.prov).then((data) => {
        this.citta_options = data;
      });
      if (auto) {
        this.content.fatturazione.citta = "";
      }
    },
    cittaSelected() {
      apiService.get("BE_form/citta/" + this.content.fatturazione.citta).then((resp) => {
        this.content.fatturazione.nazione = resp.nazione.id;
        this.content.fatturazione.regione = resp.regione.id;
        this.content.fatturazione.prov = resp.prov.id;
        this.content.fatturazione.cap = resp.cap;
        this.nazioneSelected(false);
      });
    },
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
    modalClose() {
      this.modal = false;
      setTimeout(() => {
        this.modaldata = {};
      }, 300);
    },
    svuota() {
      if (!this.content.fatturazione.fe_attivo) {
        this.content.fatturazione.fe_codsdi = "";
        this.content.fatturazione.fe_pec = "";
      }
    },
    uuid() {
      var uuidgen = short();
      return uuidgen.new();
    },
    _scontata(tariffa) {
      return typeof tariffa.prezzo_base !== "undefined" && tariffa.prezzo_base !== tariffa.prezzo;
    }
  }
};
</script>

<style lang="scss">
.layerwaitglob {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
}
.layerwaitglob-div {
  width: 100%;
  height: 100%;
}
.home {
  .side-bar {
    .coupon-wrapper {
      padding: 0;
      .sidepanel {
        min-width: 0 !important;
        margin: 0;
        padding: 6px 10px;
        width: calc(400% - 80px);
      }
      &.side-btn.side-doppio .btn-doppio.bottone {
        min-width: 80px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .coupon-msg {
        margin-left: 2px;
        font-size: 0.9em;
        font-style: italic;
        .fa.check {
          margin-right: 3px;
        }
      }
    }
  }
  // ____________________________ CONTENT ________________________________
  .content-form {
    color: #2a364d;
    background-color: white;
    margin: 20px 7px;
    position: relative;
    z-index: 1;
    // padding: 0 10% 2em;
    .title {
      text-transform: uppercase;
      padding: 0.5em 8.8%;
      font-weight: bold;
      margin: 0 0 1em;
      background-color: #c3c9df;
    }
    .policy-text:not(:first-child) {
      border-top-width: 2px;
      border-top-style: solid;
    }
    &.ccs-form {
      height: 0;
      opacity: 0;
      transition: height 0.2s linear, opacity 0s linear 0.2s;
      &.ccs-visible {
        opacity: 1;
        height: 400px;
        transition: height 0.2s linear, opacity 0s linear;
        .layer-wait {
          background-color: #00000022;
        }
        .layer-wait,
        .layer-done {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 400px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .layer-wait-icon,
          .layer-done-icon {
            color: var(--base-color);
            text-align: center;
            font-size: 100px;
          }
        }
      }
      .cyb-frame {
        border: none;
        width: 100%;
        height: 320px;
      }
    }
  }
}
</style>
