<template>
  <div class="home" :style="{ '--base-dark': $theme.basedark, '--base-light': $theme.baselight, '--base-color': $theme.basecolor }">
    <div class="row" :style="{ minHeight: 'calc(100vh - 12em)' }">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" style="min-width: 350px; padding-bottom: 20px">
        <!-- -------------------------------------------- S I D E P A N E L -------------------------------------------- -->
        <div class="side-bar">
          <div class="sidepanel shadow">
            <div class="row">
              <!-- ---------------- DATEPICKER ---------------- -->
              <div class="col-picker col-10 col-lg-11 col-xl-10">
                <div class="side picker" style="margin-top: 0">
                  <cyb-hotel-date-picker v-model="form.periodo" :labels="labels.datepicker" @input="clearContent()" />
                </div>
              </div>
              <!-- -------------------- NOTTI -------------------- -->
              <div class="col-notti col-2 col-lg-1 col-xl-2">
                <div class="side notti">
                  <fa class="fa moon" :icon="['far', 'moon']" :title="labels.notti" :style="{ color: $theme.basecolor }" /><span class="label">{{ form.periodo.notti ? form.periodo.notti : 1 }}</span>
                </div>
              </div>
            </div>
            <!-- -------------------- CAMERE -------------------- -->
            <div class="side ospiti">
              <div v-for="camera in form.ospiti" :key="camera.key" :class="'room' + (camera.active ? ' room-active' : '')">
                <div :class="'room-header' + (camera.codice ? ' room-selected' : '')">
                  <!-- ---- label ---- -->
                  <div class="room-title" @click="activateRoom(camera)">
                    <div>{{ camera_label(camera) }}</div>
                    <div class="room-subtitle">{{ tariffa_label(camera) }}</div>
                  </div>
                  <div class="room-params">
                    <!-- ---- adulti ---- -->
                    <div class="room-data">
                      <div class="room-label">{{ labels.Adulti2 }}</div>
                      <div class="room-btn">
                        <fa class="fa minus click" :style="{ color: $theme.basecolor }" :icon="['fas', 'minus']" @click="removeGuest(camera)" />
                        <span class="counter">{{ camera.adulti }}</span>
                        <fa class="fa plus click" :style="{ color: $theme.basecolor }" :icon="['fas', 'plus']" @click="addGuest(camera)" />
                      </div>
                    </div>
                    <!-- ---- bambini ---- -->
                    <div v-if="ageDiff > 0" class="room-data">
                      <div class="room-label">{{ labels.Bambini2 }}</div>
                      <div class="room-btn">
                        <fa class="fa minus click" :style="{ color: $theme.basecolor }" :icon="['fas', 'minus']" @click="removeKid(camera)" />
                        <span class="counter">{{ camera.bambini.length }}</span>
                        <fa class="fa plus click" :style="{ color: $theme.basecolor }" :icon="['fas', 'plus']" @click="addKid(camera)" />
                      </div>
                    </div>
                    <!-- ---- cestino ---- -->
                    <fa class="fa trash click" :style="{ color: $theme.basecolor }" :title="labels.btnCestino + ' ' + (camera.key + 1)" :icon="['fas', 'trash-alt']" @click="removeRoom(camera)" />
                  </div>
                </div>
                <!-- ---- età bambini ---- -->
                <div>
                  <span v-if="camera.bambini.length > 0 && ageDiff > 0" class="kids-label">{{ labels.Eta2 }}</span>
                  <span v-for="(kid, idk) in camera.bambini" :key="idk" class="room-kids">
                    <select v-model="camera.bambini[idk]" @click="clearContent()">
                      <option selected :value="minAge">{{ minAge }}</option>
                      <option v-for="(age, idx) in ageDiff" :key="idx" :value="age + minAge">{{ age + minAge }}</option>
                    </select>
                  </span>
                </div>
              </div>
              <!-- -------------------- BOTTONE AGGIUNGI -------------------- -->
              <div v-if="form.ospiti.length < maxRooms" class="room-add-wrapper">
                <div class="room-add click" :style="{ color: $theme.basecolor }" @click="addRoom">
                  <fa class="fa plus add" :icon="['fas', 'plus']" />
                  {{ labels.btnAggiungi }}
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------- BOTTONE AGGIORNA -------------------- -->
          <div class="side-btn">
            <div v-if="!goNext" :class="'bottone cybtn shadow' + (reload ? ' reload' : '')" :style="{ backgroundColor: $theme.basecolor }" @click="loadContent()">
              <fa class="fa chevron-right" :icon="['fas', 'chevron-right']" />
              {{ labels.btnAggiorna }}
              <fa class="fa search" :icon="['fas', 'search']" />
              <fa class="fa chevron-left" :icon="['fas', 'chevron-left']" />
            </div>
            <div v-else class="bottone cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="nextPhase()">
              {{ labels.btnAvanti }}
              <fa class="fa search" :icon="['fas', 'chevron-right']" />
            </div>
          </div>
          <!-- -------------------- SELEZIONE MULTIPROPRIETA -------------------- -->
          <div v-if="multipro && multipro.length > 0" class="sidepanel shadow side-multipro" style="margin-top: 0">
            <!-- eslint-disable-next-line -->
            <div v-if="nota_multi[activeLang]" class="side" style="margin-top: 0; margin-bottom: 10px" v-html="nota_multi[activeLang]"></div>
            <div class="mpro-list">
              <div v-for="hotel in multipro" :key="hotel.id" class="side mpro-btn" @click="switchHotel(hotel.idazienda)">
                <div class="mpro-wrapper">
                  <div class="mpro-content">
                    <div class="mpro-logo">
                      <img v-if="hotel.logo" :src="hotel.logo" />
                    </div>
                    <div class="mpro-title" :style="{ color: $theme.basecolor }">
                      {{ hotel.ragsoc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-list col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <!-- -------------------------------------------- C O N T E N T -------------------------------------------- -->
        <div class="content">
          <div v-show="form.ospiti.length > 1 && !reload" class="room_num_of">{{ camera_label_active(form.ospiti) }} di {{ form.ospiti.length }}</div>
          <div v-for="alloggio in alloggi" :id="alloggio.key" :key="alloggio.key" class="alloggio shadow" :disabled="alloggio.disabled || reload">
            <div class="alloggio-data shadow" :style="{ backgroundImage: 'url(' + alloggio.sfondo + ') !important', borderRight: '4px solid ' + $theme.basecolor, cursor: 'pointer' }" @click="modalRoom(alloggio)">
              <div class="alloggio-dim">
                <div class="row">
                  <div class="col-8 col-md-10">
                    <!-- -------------------- TITOLO -------------------- -->
                    <div class="titolo alloggio-content">{{ alloggio.titolo }}</div>
                    <!-- ---------------- MAX OSPITI ---------------- -->
                    <div v-if="alloggio.ospiti.adulti > 0 || alloggio.ospiti.bambini > 0" class="max-ospiti alloggio-content">
                      <span class="max-icons">
                        <!-- ---- icone adulti ---- -->
                        <span v-if="alloggio.ospiti.adulti < 6">
                          <fa v-for="(adulti, idx) in parseInt(alloggio.ospiti.adulti)" :key="idx" class="fa user-alt" :icon="['fas', 'user-alt']" />
                        </span>
                        <span v-else>
                          <fa class="fa user-alt" :icon="['fas', 'user-alt']" /><b>x{{ alloggio.ospiti.adulti }}</b>
                        </span>
                        <!-- ---- + ---- -->
                        <span v-if="alloggio.ospiti.adulti > 0 && alloggio.ospiti.bambini > 0">+</span>
                        <!-- ---- icone bambini ---- -->
                        <span v-if="alloggio.ospiti.bambini < 6">
                          <fa v-for="(bambini, idx) in parseInt(alloggio.ospiti.bambini)" :key="idx + bambini" class="fa user-alt bambino" :icon="['fas', 'user-alt']" />
                        </span>
                        <span v-else>
                          <fa class="fa user-alt bambino" :icon="['fas', 'user-alt']" /><b>x{{ alloggio.ospiti.bambini }}</b>
                        </span>
                      </span>
                      <!-- ---- label ---- -->
                      <span class="max-labels">{{ ospiti_label(alloggio.ospiti) }}</span>
                    </div>
                  </div>
                  <div class="col-4 col-md-2 align-self-start">
                    <!-- ---------------- SUPERFICIE ---------------- -->
                    <div v-if="alloggio.superficie > 0" class="superficie">
                      <fa :class="'fa expand-alt'" :icon="['fas', 'expand-arrows-alt']" />
                      <span>{{ alloggio.superficie }} m²</span>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 12px">
                  <div class="col-8 col-md-10">
                    <!-- ---------------- DESCRIZIONE ---------------- -->
                    <div class="descrizione alloggio-content">{{ alloggio.descrizione }}</div>
                    <!-- -------------------- SERVIZI -------------------- -->
                    <div v-if="alloggio.servizi && alloggio.servizi.length > 0" class="servizi alloggio-content">
                      <div class="servizi-title">{{ labels.Serv }}</div>
                      <div class="row">
                        <div v-for="(servizio, idx) in alloggio.servizi.slice(0, 6)" :key="idx" class="servizio-cell col-12 col-md-6">
                          <div v-if="alloggio.servizi.length > 6 && idx === 5" :title="servizi_left(alloggio.servizi)">
                            <fa :class="'fa plus icon-more'" :icon="['fas', 'plus']" />
                            <span>{{ labels.ServPrima + _label(labels.ServPrima) + (alloggio.servizi.length - 5) + _label(labels.ServDopo) + labels.ServDopo }}</span>
                          </div>
                          <div v-else>
                            <fa :class="'fa ' + servizio.id" :title="servizio.servizio" :icon="_icon(servizio.id)" />
                            <span>{{ servizio.servizio }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-md-2 align-self-end">
                    <!-- -------------------- PREZZO -------------------- -->
                    <div v-if="prezzo_min(alloggio.tariffe) != '0,00'" class="prezzo alloggio-content">
                      <div class="prezzo-label">{{ labels.PartireDa }}</div>
                      <div class="prezzo-content">
                        <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                        {{ prezzo_min(alloggio.tariffe) }}
                      </div>
                    </div>
                    <div v-if="prezzo_min(alloggio.tariffe) == '0,00'" class="prezzo alloggio-content">
                      <div class="prezzo-label">{{ labels.nondispbe }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- -------------------------------- T A R I F F E -------------------------------- -->
            <div v-if="alloggio.tariffe && alloggio.tariffe.length > 0" class="card-righe">
              <div class="card-testata">
                <div class="row">
                  <div class="col-6 col-sm-5 card-riga">
                    <div class="card-label">{{ labels.Tariffa }}</div>
                  </div>
                  <div class="col-6 col-sm-4 card-riga">
                    <div class="card-label">{{ labels.Trattamento }}</div>
                  </div>
                  <div class="col-12 col-sm-3 card-riga card-prezzo-header">
                    <div class="card-label">{{ labels.Prez }}</div>
                  </div>
                </div>
              </div>
              <!-- -------------------- RIGA TARIFFA -------------------- -->
              <div class="tariffa">
                <div v-for="tariffa in alloggio.tariffe" :key="tariffa.key" :class="'row' + (tariffa.sconto && tariffa.sconto > 0 ? ' scontato' : '')">
                  <!-- ---- tipo tariffa ---- -->
                  <div class="col-6 col-sm-5 card-riga" :style="{ cursor: 'pointer' }" @click="modalRate(tariffa)">
                    <div class="card-label">{{ tariffa.tipo }}</div>
                  </div>
                  <!-- ---- trattamento ---- -->
                  <div class="col-6 col-sm-4 card-riga card-label-counter">
                    <div class="card-label trattamento">
                      {{ tariffa.trattamento }}
                    </div>
                    <!-- ---- counter ---- -->
                    <div v-if="tariffa.selected > 0" class="counter-wrapper" :style="{ backgroundColor: $theme.basecolor }">
                      <div class="card-counter" :title="labels.btnCestinoPreno">
                        <div class="counter-text">
                          {{ tariffa.selected }}
                        </div>
                        <div class="counter-delete">
                          <fa class="fa times click" :icon="['fas', 'times']" @click="removeSelect(alloggio, tariffa)" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ---- prezzo ---- -->
                  <div class="col-12 col-sm-3 card-riga card-prezzo">
                    <div class="row align-self-center">
                      <div class="prezzo-div col-6 col-sm-12 col-md-12 col-lg-6 prezzo-main align-self-center counter-2">
                        <!-- ---- Prezzo con sconto ---- -->
                        <div v-if="tariffa.sconto && tariffa.sconto > 0" class="prezzo-div-inner">
                          <div class="prezzo-originale">
                            <svg class="line-trough" viewBox="0 0 100 100" preserveAspectRatio="none">
                              <path d="M20 10 L20 10 L90 90" fill="none" stroke="#db4e4e" stroke-width="6" stroke-linecap="round" />
                            </svg>
                            <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                            {{ tariffa.prezzo }} <span class="sconto">-{{ tariffa.sconto }}%</span>
                          </div>
                          <div>
                            <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                            {{ _sconto(tariffa) }}
                          </div>
                        </div>
                        <!-- ---- Prezzo ---- -->
                        <div v-else class="prezzo-div-inner">
                          <fa :class="'fa euro'" :icon="['fas', $theme.eurosign]" />
                          {{ tariffa.prezzo }}
                        </div>
                        <!-- ---- counter 2 ---- -->
                        <div v-if="tariffa.selected > 0" class="counter-wrapper-2">
                          <div class="card-counter" :title="labels.btnCestinoPreno" :style="{ backgroundColor: $theme.basecolor }">
                            <div class="counter-text">
                              {{ tariffa.selected }}
                            </div>
                            <div class="counter-delete">
                              <fa class="fa times click" :icon="['fas', 'times']" @click="removeSelect(alloggio, tariffa)" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- ---- bottone prenota ---- -->
                      <div class="prezzo-div col-6 col-sm-12 col-md-12 col-lg-6 prezzo-btn align-self-center counter-2">
                        <div v-if="tariffa.disponibili > 0 && !goNext" class="btn-prenota cybtn" :style="{ backgroundColor: $theme.basecolor }" @click="select(alloggio, tariffa)">
                          {{ labels.btnPrenota }}
                          <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
                        </div>
                        <div v-else-if="tariffa.disponibili < 1" class="cybtn btn-esaurito" disabled>
                          {{ labels.btnEsaurito }}
                        </div>
                        <div v-else class="cybtn btn-esaurito" disabled>
                          {{ labels.btnPrenota }}
                          <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
                        </div>
                      </div>
                      <div class="prezzo-line col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="goNext" class="btn-footer">
          <div class="btn-avanti cybtn shadow" :style="{ backgroundColor: $theme.basecolor }" @click="nextPhase()">
            {{ labels.btnAvanti }}
            <fa class="fa arrow" :icon="['fas', 'chevron-right']" />
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------- F O O T E R -------------------------------------------- -->
    <cyb-footer :labels="labels" />
    <!-- -------------------------------------------- M O D A L S -------------------------------------------- -->
    <cyb-modal :visible="modal" :modaldata="modaldata" @modalClose="modalClose">
      <div v-if="modaldata.servizi" class="data-item data-servizi">
        <div :style="{ fontWeight: 'bold' }">{{ labels.Serv }}:</div>
        <div class="row">
          <div v-for="(srv, cdx) in modaldata.servizi" :key="cdx" class="col-12 col-sm-6 col-lg-12 col-xl-6"><fa :class="'fa ' + srv.id" :title="srv.servizio" :icon="_icon(srv.id)" /> {{ srv.servizio }}</div>
        </div>
      </div>
      <div v-if="modaldata.policy" class="data-item data-policy">{{ modaldata.policy }}</div>
    </cyb-modal>
  </div>
</template>

<script>
import CybHotelDatePicker from "../components/cybhoteldatepicker.vue";
import CybFooter from "../components/cybfooter.vue";
import CybModal from "../components/cybmodal.vue";
import apiService from "../services/api.js";
import formService from "../services/form";
import baseMixin from "../mixins/base";

export default {
  components: {
    CybHotelDatePicker,
    CybFooter,
    CybModal
  },
  mixins: [baseMixin],
  data() {
    return {
      form: {
        periodo: {
          dataarrivo: "",
          datapartenza: "",
          notti: 0
        },
        ospiti: [{ key: 0, adulti: 1, bambini: [], active: false }]
      },
      alloggi: [],
      labels: { privacy: {} },
      maxAge: 10,
      minAge: 0,
      maxRooms: 20,
      total: 0,
      reload: false,
      back: false,
      autoload: false,
      modal: false,
      modaldata: {},
      multipro: [],
      nota_multi: { ita: "" }
    };
  },
  computed: {
    goNext() {
      return this.total >= this.form.ospiti.length;
    },
    activeRoom() {
      return this.form.ospiti.find((el) => el.active === true);
    },
    ageDiff() {
      return this.maxAge - this.minAge;
    },
    activeLang() {
      if (this.$store.lang) {
        return this.$store.lang.toLowerCase();
      }
      return "ita";
    }
  },
  mounted() {
    this.$store.err = [];
    // parametri in POST
    if (this._postData()) {
      this.$store.db = this.$postData.RPC_db;
      this.$store.idhotel = this.$postData.idhotel;
      this.$store.lang = this.data_check(this.$postData.lan, "ita");
      this.autoload = true;
    } else {
      // parametri in URL
      this.$store.db = this.$route.query.RPC_db;
      this.$store.idhotel = this.$route.query.idhotel;
      this.$store.lang = this.$route.query.lan;
    }
    this.$root.$on("rooms:reload", () => {
      this.loadContent();
    });
    formService.init(this);

    // chiamata backend per confermare i dati / recuperare altra roba
    if (this.$store.db && this.$store.idhotel) {
      apiService
        .get("BE_booking/" + this.$store.db + "/" + this.$store.idhotel)
        // tutto ok
        .then((resp) => {
          if (resp.hotel && typeof resp.hotel === "object") {
            this.maxAge = parseInt(resp.maxeta);
            this.minAge = parseInt(resp.mineta);
            this.loadHotel(resp);
            // se devo precaricare la ricerca
            if (this.autoload) {
              // this.form.periodo.dataarrivo = this.data_check(this.$postData.da_data, this.form.periodo.dataarrivo);
              // this.form.periodo.datapartenza = this.data_check(this.$postData.a_data, this.form.periodo.datapartenza);
              this.form.ospiti = [{ key: 0, adulti: parseInt(this.data_check(this.$postData.adulti_1, 1)), bambini: [], active: false }];
              for (let i = 0; i < parseInt(this.data_check(this.$postData.bambini_1, 0)); i++) {
                this.form.ospiti[0].bambini.push(this.minAge);
              }
            }
          } else {
            this._error("ERR_HOTEL");
          }
        })
        // errore backend
        .catch((err) => {
          if (err.msg && err.msg.length > 0) {
            for (let i = 0; i < err.msg.length; i++) {
              this.$store.err.push(err.msg[i]);
            }
            this._error();
          } else {
            this._error("ERR_GENERIC");
          }
        });
      // paramentri mancanti
    } else {
      var err = [];
      if (!this.$store.db || !this.$store.idhotel) {
        err.push("ERR_RPC");
      }
      this._error(err);
    }
  },
  destroyed() {
    this.$root.$off("rooms:reload");
  },
  methods: {
    // :::::::::::::::::::::::::::::::: GESTIONE DATI ::::::::::::::::::::::::::::::::
    // gestione passaggio alla maschera servizi
    nextPhase() {
      this.form.ospiti.map((el) => {
        el.servizi = [];
      });
      this._goNext("/servizi", this.form);
    },
    // cambia struttura
    switchHotel(idhotel) {
      window.location.href = window.location.href.replace(this.$store.idhotel, idhotel);
    },
    // carica dati hotel
    loadHotel(data) {
      // dati principali hotel
      for (var item in data.hotel) {
        if (Object.prototype.hasOwnProperty.call(data.hotel, item)) {
          this.$hotel[item] = data.hotel[item];
        }
      }
      this.nota_multi = data.nota_multi;

      // colori e sfondo
      this.$theme.basecolor = data.colore;
      this.$theme.eurosign = data.eurosign;
      this.$theme.basedark = formService.brightness(this.$theme.basecolor, -50);
      this.$theme.baselight = formService.brightness(this.$theme.basecolor, 50);
      if (data.sfondo) {
        this.$theme.background = data.sfondo;
      }

      // bottoni multiproprietà
      this.multipro = data.multipro;
    },
    // carica dati camere
    loadContent() {
      this.$store.lastpage = "camere";

      this.clearContent();
      apiService.post("BE_camere/" + this.$store.db + "/" + this.$store.idhotel + "/" + this.$store.lang + "/" + this.$store.idlang, this.form).then((resp) => {
        this.alloggi = [];
        // this.$store.form = null;
        if (resp.camere && typeof resp.camere === "object") {
          this.alloggi = [...resp.camere];
          this.activateRoom();
          // se torno indietro da una maschera ri-creo la situazione finale
          if (this.$store.form && this.form.ospiti) {
            this.back = true;
            this.form.ospiti.map((el) => {
              if (el.codice && el.tariffa) {
                var al = this.alloggi.findIndex((all) => all.key === el.codice);
                var tr = this.alloggi[al].tariffe.findIndex((tar) => tar.key === el.tariffa.key);
                this.select(this.alloggi[al], this.alloggi[al].tariffe[tr]);
              }
            });
          }
          this.back = false;
        }
        this.reload = false;
      });
    },
    clearContent() {
      if (!this.reload) {
        // svuoto camere
        this.alloggi.map((all) => {
          all.disabled = true;
          all.tariffe.map((tar) => {
            tar.disponibili = tar.max;
            tar.selected = 0;
          });
        });
        // svuoto selezione form
        this.form.ospiti.map((cam) => {
          cam.active = false;
          cam.titolo = "";
          cam.codice = "";
          cam.tariffa = {};
        });
        // reimposto parametri base
        this.total = 0;
        this.reload = true;
      }
    },
    // :::::::::::::::::::::::::::::::: FILTRI ::::::::::::::::::::::::::::::::
    // bottoni (+) / (-) nella box dei filtri
    addRoom() {
      var i = this.form.ospiti.length;
      if (i < this.maxRooms) {
        this.form.ospiti.push({ key: i, adulti: 1, bambini: [], active: false });
      }
      this.clearContent();
    },
    removeRoom(camera) {
      if (!camera.codice && this.form.ospiti.length > 1) {
        this.form.ospiti.splice(camera.key, 1);
        this.form.ospiti.map((el) => {
          if (el.key > camera.key) {
            el.key--;
          }
        });
        this.clearContent();
      }
    },
    addGuest(camera) {
      if (!camera.codice) {
        camera.adulti++;
        this.clearContent();
      }
    },
    removeGuest(camera) {
      if (!camera.codice && camera.adulti > 1) {
        camera.adulti--;
        this.clearContent();
      }
    },
    addKid(camera) {
      if (!camera.codice) {
        camera.bambini.push(this.minAge);
        this.clearContent();
      }
    },
    removeKid(camera) {
      if (!camera.codice) {
        camera.bambini.pop();
        this.clearContent();
      }
    },
    // :::::::::::::::::::::::::::::::: SELEZIONE ::::::::::::::::::::::::::::::::
    // seleziona una tariffa
    select(alloggio, tariffa) {
      if (!alloggio.disabled) {
        // selected++
        if (tariffa.selected === undefined) {
          tariffa.selected = 0;
        }
        if (tariffa.disponibili > 0) {
          tariffa.selected++;
          this.total++;
        }
        // disponibili -1 di TUTTE le tariffe
        alloggio.tariffe = alloggio.tariffe.map((item) => {
          item.disponibili--;
          return item;
        });
        this.cameraNEXT(tariffa, alloggio);

        // ____ LANCIA EVENTO NEXTPHASE -> SELEZIONE SERVIZI SUPPLEMENTARI ____
        if (this.goNext && !this.back) {
          this.nextPhase();
        }
      }
    },
    // de-seleziona una tariffa
    removeSelect(alloggio, tariffa) {
      if (!alloggio.disabled) {
        // selected--
        if (tariffa.selected > 0) {
          tariffa.selected--;
          this.total--;
        }
        // disponibili +1 di TUTTE le tariffe
        alloggio.tariffe = alloggio.tariffe.map((item) => {
          if (item.disponibili < item.max) {
            item.disponibili++;
          }
          return item;
        });
        this.cameraPREV(tariffa, alloggio.key);
      }
    },
    cameraNEXT(tariffa, alloggio) {
      var camera = this.activeRoom;
      if (camera) {
        camera.active = false;
        camera.tariffa = {
          key: tariffa.key,
          prezzo: tariffa.prezzo,
          tipo: tariffa.tipo,
          trattamento: tariffa.trattamento
        };
        camera.titolo = alloggio.titolo;
        camera.codice = alloggio.key;
        camera.policy = alloggio.policy;
        if (tariffa.policy_id) {
          camera.policy = tariffa.policy_id;
        }
        camera.eta = alloggio.ospiti.eta;
        this.activateRoom();
      }
    },
    // attivo camera precedente
    cameraPREV(tariffa, alloggio) {
      var camera = this.activeRoom;
      if (camera) {
        camera.active = false;
      }

      // trovo l'ULTIMA camera con la tariffa selezionata
      var index = formService.lastIndex(this.form.ospiti, (el) => el.codice === alloggio && el.tariffa.key === tariffa.key);
      // var index = [...this.form.ospiti].reverse().findIndex(el => el.codice === alloggio && el.tariffa.key === tariffa.key);
      this.form.ospiti[index].tariffa = {};
      this.form.ospiti[index].titolo = "";
      this.form.ospiti[index].codice = "";
      this.form.ospiti[index].policy = "";
      this.form.ospiti[index].eta = "";
      this.activateRoom();
    },
    activateRoom(room = null) {
      if (room && !this.reload && !room.codice) {
        this.activeRoom.active = false;
        room.active = true;
      } else if (!room) {
        // attvo la prima camera NON SELEZIONATA
        var camera = this.form.ospiti.find((el) => !el.codice);
        if (camera) {
          camera.active = true;
        }
      }
      this.validateRooms();
    },
    // ricalcolo tutti i prezzi delle tariffe e disattivo le camere non selezionabili
    validateRooms() {
      var maxbambini;
      this.alloggi.map((all) => {
        if (!this.goNext && this.activeRoom) {
          maxbambini = this.activeRoom.bambini.filter((kid) => kid >= all.ospiti.eta).length;
          // (adulti >= adulti_filtro) && (bambini >= bambini_filtro)
          // - - - - - - - - - - O P P U R E - - - - - - - - - - - -
          // (adulti >= adulti_filtro + bambini_filtro)
          if ((all.ospiti.adulti >= this.activeRoom.adulti && all.ospiti.bambini >= maxbambini) || all.ospiti.adulti >= this.activeRoom.adulti + maxbambini) {
            all.disabled = false;
          } else {
            all.disabled = true;
          }
          all.tariffe.map((tar) => {
            tar.prezzo = tar.prezzo_base;
            tar.prezzo = this.prezzo_new(this.activeRoom, all.ospiti.eta, tar, this.form.periodo.notti);
          });
        }
      });
    },
    // :::::::::::::::::::::::::::::::: UTILITY ::::::::::::::::::::::::::::::::
    prezzo_new(camera, eta, tariffa, notti) {
      if (camera && (tariffa.supp_adulto || tariffa.supp_bambino)) {
        // recupero il numero di ospiti dell'attuale camera attiva
        var na = camera.adulti;
        var nb = camera.bambini.filter((kid) => kid >= eta).length;
        // calcolo il prezzo + supplementi
        tariffa.prezzo = formService._toStr(formService._toFloat(tariffa.prezzo) + (na * formService._toFloat(tariffa.supp_adulto) + nb * formService._toFloat(tariffa.supp_bambino)) * notti);
      }
      return tariffa.prezzo;
    },
    modalRoom(camera) {
      if (!camera.disabled) {
        this.modaldata = {
          titolo: camera.titolo,
          descrizione: camera.descrizione,
          servizi: camera.servizi,
          immagini: camera.immagini
        };
        this.modal = true;
      }
    },
    modalRate(tariffa) {
      this.modaldata = {
        titolo: tariffa.tipo,
        descrizione: tariffa.descrizione,
        policy: tariffa.policy,
        immagini: tariffa.immagini
      };
      this.modal = true;
    },
    modalClose() {
      this.modal = false;
      setTimeout(() => {
        this.modaldata = {};
      }, 300);
    },
    data_check(data, def = "") {
      if (typeof data !== "undefined" && data) {
        return data;
      } else {
        return def;
      }
    },
    ospiti_label(ospiti) {
      var label = "";
      if (ospiti.adulti > 0) {
        if (ospiti.adulti > 1) {
          label += ospiti.adulti + " " + this.labels.Adulti2;
        } else {
          label += "1 " + this.labels.Adulto;
        }
      }
      if (ospiti.bambini > 0) {
        if (ospiti.adulti > 0) {
          label += " + ";
        }
        if (ospiti.bambini > 1) {
          label += ospiti.bambini + " " + this.labels.Bambini2;
        } else {
          label += "1 " + this.labels.Bambino;
        }
      }
      return label;
    },
    servizi_left(servizi) {
      var tool = "";
      if (servizi.length > 6) {
        servizi.slice(5).map((el) => {
          tool += "\n• " + el.servizio;
        });
      }
      return tool;
    },
    _postData() {
      return this.$postData && this.$postData.RPC_db && this.$postData.idhotel;
    }
  }
};
</script>

<style lang="scss">
@keyframes clickmeleft {
  0% {
    margin-left: 10px;
  }
  100% {
    margin-left: 30px;
  }
}
@keyframes clickmeright {
  0% {
    margin-right: 10px;
  }
  100% {
    margin-right: 30px;
  }
}
.room_num_of {
  background-color: white;
  font-size: 1.3em;
  font-weight: bold;
  color: #000;
  text-align: center;
  border-top: 4px solid var(--base-color);
}
.home {
  // ____________________________ SIDE-BAR ________________________________
  .side-bar {
    .sidepanel .side.ospiti {
      .room {
        .room-header .room-params {
          min-width: 170px;
          display: flex;
          flex: 1 1 auto;
          justify-content: flex-end;
          .room-data {
            align-self: center;
            padding-right: 13px;
            .room-label {
              display: block;
              text-align: center;
              font-size: 14px;
              margin-bottom: -5px;
            }
            .counter {
              padding: 5px;
            }
          }
          .trash {
            align-self: center;
            font-size: 18px;
          }
        }
        .kids-label {
          display: inline-block;
          min-width: 40px;
          margin-right: 4px;
        }
        .room-kids {
          padding: 0 4px 0 0;
        }
      }
      .plus,
      .minus {
        border: 2px solid;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        padding: 1px;
        margin: 2px 0 -2px;
      }
      .room-add-wrapper {
        // text-align: center;
        display: flex;
        justify-content: center;
        .room-add {
          padding: 6px 0;
          display: inline-block;
          text-align: center;
          font-weight: bold;
          .add {
            border: none;
          }
        }
      }
    }
    // ____________________________ AGGIORNA ________________________________
    .side-btn {
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      margin: 0 7px;
      &.side-doppio {
        // padding: 0 7px;
        justify-content: space-between;
        .btn-doppio.bottone {
          min-width: 120px !important;
          // width: 170px !important;
          // width: 45% !important;
          &:first-child {
            margin-right: 15px;
          }
          &:last-child {
            margin-left: 15px;
          }
          .indietro {
            margin-right: 5px;
          }
        }
      }
      .bottone {
        position: relative;
        font-size: 1.1em;
        font-weight: bold;
        min-width: 345px !important;
        width: 100%;
        &.reload {
          .chevron-right {
            visibility: visible;
            animation: clickmeright 0.5s infinite ease-in-out alternate;
          }
          .chevron-left {
            visibility: visible;
            animation: clickmeleft 0.5s infinite ease-in-out alternate;
          }
        }
        .chevron-right,
        .chevron-left {
          font-size: 1.2em;
          visibility: hidden;
        }
        .search {
          font-size: 0.9em;
          margin-left: 5px;
        }
        &:active {
          background-color: var(--base-dark) !important;
        }
      }
    }
    // ____________________________ MULTIPROPRIETA ________________________________
    .side-multipro {
      .mpro-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .mpro-btn {
          width: calc(50% - 5px);
          &:nth-child(1),
          &:nth-child(2) {
            margin-top: 0;
          }
          cursor: pointer;
          .mpro-wrapper {
            padding-bottom: 60%;
            position: relative;
            .mpro-content {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .mpro-logo {
                width: 100%;
                max-height: calc(100% - 30px);
                flex-grow: 100;
                display: flex;
                justify-content: center;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                }
              }
              .mpro-title {
                max-width: 100%;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
  // ____________________________ CONTENT ________________________________
  .content .alloggio {
    // ____________________________ CAMERA ________________________________
    .alloggio-data .alloggio-dim {
      .superficie {
        float: right;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        margin-right: -10px;
        margin-top: -10px;
        .expand-alt {
          margin-right: 5px;
          padding: 2px;
        }
      }
      .alloggio-content {
        .max-icons {
          .bambino {
            font-size: 14px;
          }
          span {
            margin-left: 2px;
            margin-right: 2px;
          }
          b {
            margin-left: 2px;
            font-size: 0.8em;
          }
        }
        .max-labels {
          margin-left: 5px;
        }
        &.servizi {
          position: relative;
          display: inline-block;
          width: 400px;
          margin-bottom: 12px;
          .servizi-title {
            font-weight: bold;
            margin-bottom: 4px;
          }
          .row {
            .servizio-cell {
              font-size: 0.9em;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              .fa {
                width: 18px;
                margin-right: 5px;
                &.fa-circle {
                  padding: 3px;
                }
              }
            }
          }
        }
        &.prezzo {
          padding: 10px 15% 7px;
          float: right;
          background-color: rgba(0, 0, 0, 0.5);
          max-width: 140px;
          margin-right: -10px;
          margin-bottom: -10px;
          .prezzo-label {
            text-align: center;
            font-size: 0.8em;
            overflow: hidden;
            white-space: nowrap;
          }
          .prezzo-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            margin-top: -2px;
            font-weight: bold;
            font-size: 1.3em;
            .euro {
              font-size: 0.9em;
              padding-bottom: 1px;
            }
          }
        }
      }
    }
  }
}
</style>
