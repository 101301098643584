<template>
  <div id="app" class="main" :style="{ backgroundImage: 'url(' + $theme.background + ')' }">
    <cyb-tool-bar />
    <router-view />
  </div>
</template>

<script>
import CybToolBar from "./components/cybtoolbar.vue";
import apiService from "./services/api.js";

export default {
  components: { CybToolBar },
  data() {
    return {};
  },
  created() {
    document.title = this.$config.app_name;
    apiService.init(this);
    apiService._csrfRequired = [];
  }
};
</script>

<style lang="scss">
body {
  margin: 0px;
  height: 100%;
  font-size: 16px;
}
html {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2a364d;
}
.main {
  position: absolute;
  top: 0;
  left: 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.shadow {
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4) !important;
}
.click {
  cursor: pointer;
  &:active {
    filter: brightness(0.5);
  }
}
.cybtn {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  color: white;
  text-shadow: rgb(0 0 0 / 20%) 0 2px 4px;
  user-select: none;
  &.btn-esaurito {
    // background-color: gray !important;
    pointer-events: none;
  }
}
</style>
